import React from "react";
import style from './IntroductionPage.module.scss';
import {createFadeStyles} from "../../../../../utils/springStyles";
import { animated } from "react-spring";

const questions = [
   {
      title: "Answer 3 Quick Questions",
      subtitle: "Before diving into your AI Impact Score, take a few minutes to answer 3 short questions.",
   },
   {
      title: "Discover Opportunities",
      subtitle: "Find out how much of your work can be enhanced with AI tools, making you more efficient and productive.",
   },
   {
      title: "Join the New Era",
      subtitle: "Explore how AI and skills come together to supercharge your career and be part of the exciting new marketplace!",
   },
];

const IntroductionPage: React.FC<{
   changeSection: Function;
}> = props => {

   const fadeStyles = createFadeStyles(1000, 500);
   const fadeStyles2 = createFadeStyles(1000, 1000);
   const fadeStyles3 = createFadeStyles(1000, 1500);

   return (
      <>
         <div className={style['introduction-page']}>
            <animated.div style={fadeStyles}>
               <h1 className="header-1 u-margin-bottom-l">We’re thrilled to have you!</h1>
            </animated.div>
            <animated.div style={fadeStyles2} className={"questions-wrapper"}>
               {
                  questions.map((question, i) => {
                     return (
                        <div className="question" key={i}>
                           <h3 className="question-title header-3">{question.title}</h3>
                           <div className="question-subtitle">
                              <i className="fa-solid fa-check"></i>
                              <span>{question.subtitle}</span>
                           </div>
                        </div>
                     )
                  })
               }
            </animated.div>
            <animated.div style={fadeStyles3}>
               <button className="button button-primary u-margin-top-l" onClick={() => props.changeSection()}>Start building your TeamLift profile <i className="fa-solid fa-arrow-right"></i></button>
            </animated.div>
         </div>
      </>
   )
}

export default IntroductionPage;