import React, {useContext, useEffect, useState} from "react";
import style from './HookPage.module.scss';
import ModalComponent from "../../../../../ui/ModalComponent/ModalComponent";
import {animated, useTransition} from "react-spring";
import {
   FeedbackDto,
   GeneralToolsDto,
   GeneralToolsRepresentationDto,
   QuestionnaireSessionStatusEnum,
   ToolFeedbackUses, ToolInformationDto
} from "../../../../../types/AugmentationData";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../../store/auth-context";
import useApiService from "../../../../../services/api.service";
import {createTransition} from "../../../../../utils/springStyles";
import useResponsiveComponent from "../../../../../ui/ResponsiveComponent/ResponsiveComponent";

const HookPage: React.FC<{
   augmentationReport: GeneralToolsRepresentationDto,
}> = props => {

   const [selectedTool, setSelectedTool] = useState<{tool?: GeneralToolsDto | ToolInformationDto, type: 'try' | 'used' | 'want'}>();
   const [loadingData, setLoadingData] = useState<'general' | 'impact' | 'no_impact'>();
   const [augmentationReport, setAugmentationReport] = useState(props.augmentationReport);
   const [showTopTools, setShowTopTools] = useState(true);

   const transitionModal = useTransition(selectedTool, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 200 },
   });

   const transitionHookPage = createTransition(augmentationReport.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED, 500, 0);
   const transitionLoadingPage = createTransition(augmentationReport.sessionStatus !== QuestionnaireSessionStatusEnum.FINISHED, 500, 0);
   const transitionTopToolsList = createTransition(showTopTools, 750, 0);

   const authStore = useContext(AuthContext);
   const width = useResponsiveComponent().windowWidth;
   const {refreshToken} = useApiService();

   useEffect(() => {
      setAugmentationReport(props.augmentationReport);
   }, [props.augmentationReport]);

   return (
      <>
         <div className={style['hook-page']}>
            {
               transitionHookPage((style, isActive) => (
                  isActive && (
                     <animated.div className="hook-header" style={style}>
                        <img src="/assets/images/global-icons/teamlift-logo-white.svg" alt="TeamLift logo"/>
                        <button className="button button-tertiary" onClick={authStore.logout}><i
                           className="fa-solid fa-arrow-right-from-bracket"></i> Log out
                        </button>
                     </animated.div>
                  )
               ))
            }

            {
               transitionHookPage((style, isActive) => (
                  isActive && (
                     <animated.div className="hook-wrapper" style={style}>
                        <h1 className="header-1">Teamo found <span className={"text-primary-20"}>{augmentationReport.numberOfSkills || 0} skills</span>. {augmentationReport.numberOfAugSkills || 0} of them can be augmented with AI tools today!</h1>

                        <div className="tools-section">
                           <h2 className="header-2 text-surface-40 sub-header">These are the most impactful tools for your skills</h2>
                           {/*<button className="button button-primary switch-button" onClick={() => setShowTopTools(prevState => !prevState)}>{showTopTools ? "Show specific tools" : "Show general tools"}</button>*/}

                           {
                              transitionTopToolsList((style, showTopTools) => (
                                 showTopTools ? (
                                    <animated.div className="tools-wrapper" style={style}>
                                       {
                                          augmentationReport.topGeneralTools && augmentationReport.topGeneralTools.slice(0, 3).map(tool => {
                                             return (
                                                <div className="tool-card" key={tool.toolId}>
                                                   <div className="tool-card-header">
                                                      <div className="header-wrapper">
                                                         <h2 className="header-2">{tool.toolName}</h2>
                                                         <i className={`fa-solid fa-link ${tool.website ? "" : "disabled"}`}
                                                            onClick={() => tool.website && window.open(tool.website, '_blank')}></i>
                                                      </div>
                                                      <div className="estimated-users">{tool.estimatedUsers}</div>
                                                   </div>
                                                   <p className="surface-10">{tool.focus}</p>
                                                   <p className="text-surface-40">{tool.impact}</p>
                                                   <div className="buttons-wrapper">
                                                      <div className="button-wrapper">
                                                         <button className="button button-tertiary"
                                                                 onClick={() => setSelectedTool({tool, type: 'try'})}>I’d
                                                            like to try this! 🚀
                                                         </button>
                                                         {tool.feedback?.feedback === ToolFeedbackUses.START_USING &&
                                                            <i className="fa-regular fa-clock"></i>}
                                                      </div>
                                                      <div className="button-wrapper">
                                                         <button className="button button-tertiary"
                                                                 onClick={() => setSelectedTool({tool, type: 'used'})}>I’ve
                                                            already used this 🤷‍♂️
                                                         </button>
                                                         {tool.feedback?.feedback === ToolFeedbackUses.IMPACTED_MY_WORK &&
                                                            <i className="fa-regular fa-clock"></i>}
                                                         {tool.feedback?.feedback === ToolFeedbackUses.DID_NOT_IMPACTED_MY_WORK &&
                                                            <i className="fa-regular fa-clock"></i>}
                                                      </div>
                                                   </div>
                                                </div>
                                             )
                                          })
                                       }
                                    </animated.div>
                                 ) : (
                                    <animated.div className="tools-wrapper" style={style}>
                                       {
                                          augmentationReport.topSpecificTools && augmentationReport.topSpecificTools.slice(0, 3).map(tool => {
                                             return (
                                                <div className="tool-card" key={tool.id}>
                                                   <div className="tool-card-header">
                                                      <div className="header-wrapper">
                                                         <h2 className="header-2">{tool.name}</h2>
                                                         <i className={`fa-solid fa-link ${tool.websiteUrl ? "" : "disabled"}`}
                                                            onClick={() => tool.websiteUrl && window.open(tool.websiteUrl, '_blank')}></i>
                                                      </div>
                                                      <div className="estimated-users">
                                                         {
                                                            tool.hotSauce && tool.hotSauce.toolRating ?
                                                               <div className={"tool-rating-wrapper"}>
                                                                  <i className="fa-solid fa-star"></i> <span className={"tool-rating"}>{tool.hotSauce.toolRating}</span> <span> / 5</span>
                                                               </div>
                                                               :
                                                               <div className={'tool-rating-wrapper no-rating'}>
                                                                  <i className="fa-regular fa-star"></i>
                                                                  <span> No rating...</span>
                                                               </div>
                                                         }
                                                      </div>
                                                   </div>
                                                   <p className="surface-10">{tool.hotSauce && tool.hotSauce.toolPricingCategory || "No pricing information..."}</p>
                                                   <p className="text-surface-40">{tool.description.slice(0, 300)}...</p>
                                                   <div className="buttons-wrapper">
                                                      <div className="button-wrapper">
                                                         <button className="button button-tertiary" onClick={() => setSelectedTool({tool, type: 'try'})}>I’d like to try this! 🚀</button>
                                                         {tool.feedback?.feedback === ToolFeedbackUses.START_USING && <i className="fa-regular fa-clock"></i>}
                                                      </div>
                                                      <div className="button-wrapper">
                                                         <button className="button button-tertiary" onClick={() => setSelectedTool({tool, type: 'used'})}>I’ve already used this 🤷‍♂️</button>
                                                         {tool.feedback?.feedback === ToolFeedbackUses.IMPACTED_MY_WORK && <i className="fa-regular fa-clock"></i>}
                                                         {tool.feedback?.feedback === ToolFeedbackUses.DID_NOT_IMPACTED_MY_WORK && <i className="fa-regular fa-clock"></i>}
                                                      </div>
                                                   </div>
                                                </div>
                                             )
                                          })
                                       }
                                    </animated.div>
                                 )
                              ))
                           }
                        </div>

                        <h2 className={"header-2 sub-header"}><span className={"text-surface-40"}>This means there’s huge opportunity for you to</span> speed
                           up your work <span className="text-surface-40">or even</span> do things you previously
                           couldn’t!</h2>

                        <button className="button button-primary" onClick={() => setSelectedTool({tool: undefined, type: 'want'})}>Continue Your AI Journey</button>
                     </animated.div>
                  )
               ))
            }

            {
               transitionLoadingPage((style, isActive) => (
                  isActive && (
                     <animated.div style={style} className={"loading-screen"}>
                        {
                           augmentationReport.sessionStatus === QuestionnaireSessionStatusEnum.PROCESSING ?
                              <>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-waiting-icon.svg"
                                      alt="Teamo waiting icon"/>
                                 <h2 className="header-2">Just a moment, Teamo is analyzing your data.</h2>
                                 <div className="u-margin-top-m">
                                    <p>Our AI is analyzing your data.</p>
                                    <p>Your personalized insights will be ready in just a moment!</p>
                                 </div>
                              </> :
                              <>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-die-icon.svg"
                                      alt="Teamo die icon"/>
                                 <h2 className="header-2">Oops! There was an error.</h2>
                                 <div className="u-margin-top-m">
                                    <p>We're sorry, we ran into an issue.</p>
                                    <p>Please reach out to our support team at <a
                                       href="mailto:support@teamlift.co">support@teamlift.co</a> and we'll help you out.
                                    </p>
                                 </div>
                              </>
                        }
                        <button className="button button-primary" onClick={authStore.logout}><i
                           className="fa-solid fa-arrow-right-from-bracket"></i> Log out
                        </button>
                     </animated.div>
                  )
               ))
            }

            {
               transitionModal((style, tool) => (
                  tool && <ModalComponent style={style}>
                     <div className={"hook-modal"}>
                        <div className="info-section">
                           {typeOfModal(tool.type)[0]}
                        </div>
                        {
                           width > 768 &&
                           <div className="teamo-section">
                              {typeOfModal(tool.type)[1]}
                           </div>
                        }
                        <i className="fa-solid fa-xmark" onClick={() => {
                                 setLoadingData(undefined);
                                 setSelectedTool(undefined);
                             }}></i>
                         </div>
                     </ModalComponent>
                 ))
             }

         </div>
      </>
   )

   function sendToolFeedback(tool: GeneralToolsDto | ToolInformationDto, option: ToolFeedbackUses, accessToken: string) {
      const sendToolFormFeedbackURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/save-tool-feedback';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         toolId: 'toolId' in tool ? tool.toolId : tool.id,
         feedback: option
      }

      axios
         .post(sendToolFormFeedbackURL, data, {headers})
         .then((response$: AxiosResponse<FeedbackDto | null>) => {
            showNotification('success', 'Your answer has been saved!');
            setAugmentationReport(prevState => {
               return 'toolId' in tool ? prevState && {
                  ...prevState,
                  topGeneralTools: prevState.topGeneralTools?.map(t => t.toolId === tool.toolId ? ({...t, feedback: response$.data || undefined}) : t)
               } : prevState && {
                  ...prevState,
                  topSpecificTools: prevState.topSpecificTools?.map(t => t.id === tool.id ? ({...t, feedback: response$.data || undefined}) : t) as any
               }
            });
            setLoadingData(undefined);
            setSelectedTool(undefined);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     sendToolFeedback(tool, option, response$.data.accessToken);
                  })
            } else {
               setLoadingData(undefined);
               setSelectedTool(undefined);
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function typeOfModal(type: 'try' | 'used' | 'want') {
      const name = selectedTool && selectedTool.tool && ('toolName' in selectedTool.tool ? selectedTool.tool.toolName : selectedTool.tool.name);
      switch (type) {
         case 'try':
            const alreadySelectedUsing = selectedTool && selectedTool.tool && selectedTool.tool.feedback?.feedback === ToolFeedbackUses.START_USING;
            return (
               [<>
                  <h2 className="header-2">Great!</h2>
                  <p>Would you like to add <span className={'tool-name'}>{name}</span> to your learning queue? Teamo will reach out when it’s time to start the next step!</p>
                  <div className="footer-wrapper">
                      <div className="buttons-wrapper">
                          <SpinnerButton dataLoading={loadingData === 'general'} cssClasses="button button-primary"
                                         initialDisabled={alreadySelectedUsing}
                                         onClickCallback={() => {
                                             setLoadingData('general');
                                             sendToolFeedback(selectedTool!.tool!, ToolFeedbackUses.START_USING, authStore.userData.accessToken);
                                         }} title={alreadySelectedUsing ? 'Already selected' : 'Save & Return'} />
                      </div>
                      {
                          width <= 768 &&
                          <img src="/assets/images/ai-impact-score-icons/teamo-stars-icon.svg" alt="Teamo stars icon"/>
                      }
                  </div>
               </>, <img src="/assets/images/ai-impact-score-icons/teamo-stars-icon.svg" alt="Teamo stars icon"/>]
            );
         case "used":
            const alreadySelectedImpact = selectedTool && selectedTool.tool && selectedTool.tool.feedback?.feedback === ToolFeedbackUses.IMPACTED_MY_WORK;
            const alreadySelectedDidNotImpact = selectedTool && selectedTool.tool && selectedTool.tool.feedback?.feedback === ToolFeedbackUses.DID_NOT_IMPACTED_MY_WORK;
            return (
               [<>
                  <h2 className="header-2">Awesome!</h2>
                  <p>You’re already ahead of the curve, most people are still researching how to integrate AI tools in
                     their workflows.</p>
                  <h4 className="header-4">Was {name} useful for you?</h4>
                   <div className="footer-wrapper">
                       <div className="buttons-wrapper">
                           <SpinnerButton dataLoading={loadingData === 'impact'} initialDisabled={loadingData && loadingData !== 'impact' || alreadySelectedImpact}
                                          cssClasses="button button-secondary" onClickCallback={() => {
                               setLoadingData('impact');
                               sendToolFeedback(selectedTool!.tool!, ToolFeedbackUses.IMPACTED_MY_WORK, authStore.userData.accessToken);
                           }} title={alreadySelectedImpact ? 'Already selected (Yes, useful for my work)' : 'Yes, useful in my work 🥳'} />
                           <SpinnerButton dataLoading={loadingData === 'no_impact'} initialDisabled={loadingData && loadingData !== 'no_impact' || alreadySelectedDidNotImpact}
                                          cssClasses="button button-secondary" onClickCallback={() => {
                               setLoadingData('no_impact');
                               sendToolFeedback(selectedTool!.tool!, ToolFeedbackUses.DID_NOT_IMPACTED_MY_WORK, authStore.userData.accessToken);
                           }} title={alreadySelectedDidNotImpact ? 'Already selected (No, it didn’t impact my work)' : 'No, it didn’t impact my work 😔'} />
                       </div>
                       {
                           width <= 768 &&
                           <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>
                       }
                   </div>
               </>, <img src="/assets/images/ai-impact-score-icons/teamo-really-happy-icon.svg" alt="Teamo really happy icon"/>]
            );
         case "want":
            return (
               [<>
                  <h2 className="header-2">Awesome!</h2>
                  <p className={"continue-ai"}>Use the button below to schedule your next session, and we’ll send you the next steps.</p>
                  <div className="footer-wrapper">
                     <div className="buttons-wrapper">
                        <button className={"button button-primary"} onClick={() => window.open('https://www.teamlift.co/session', '_blank')}>Schedule Your Session</button>
                     </div>
                     {
                        width <= 768 &&
                        <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                             alt="Teamo hands closed eyes icon"/>
                     }
                  </div>
               </>, <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                         alt="Teamo hands closed eyes icon"/>]
            );
         default:
            return [<></>, <></>];
      }
   }
}

export default HookPage;