import React from "react";
import { animated } from "react-spring";
import style from './ModalComponent.module.scss';

const ModalComponent: React.FC<{children: React.ReactNode, style: any}> = props => {
    return (
        <>
            <animated.div className={style['modal-component']} style={props.style}>
                <div className="modal-card">
                    {props.children}
                </div>
            </animated.div>
        </>
    );
}

export default ModalComponent;