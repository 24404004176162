import React, {useContext, useEffect, useState} from "react";
import style from './WorkshopComponent.module.scss';
import AuthContext from "../../../../../store/auth-context";
import useLoadingSpinner from "../../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../../services/api.service";
import {
   ExtractedSkillsDto,
   SkillMatchDto,
   ToolCoverageDto,
   ToolDto
} from "../../../../../types/AugmentationData";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../../../../types/ErrorData";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";
import Tooltip from "../../../../../services/tooltip.service";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import {animated, useTransition} from "react-spring";
import {useNavigate} from "react-router-dom";
import {createFadeStyles} from "../../../../../utils/springStyles";

const WorkshopComponent: React.FC<{
   skills: ExtractedSkillsDto[],
   showToolModal: (tool: ToolDto) => void,
}> = props => {

   const [skills, setSkills] = useState<ExtractedSkillsDto[]>([]);
   const [skillMatch, setSkillMatch] = useState<SkillMatchDto>();
   const [toolCoverage, setToolCoverage] = useState<ToolCoverageDto>();
   const [dataLoading, setDataLoading] = useState<number>();
   const [dataLoadingTool, setDataLoadingTool] = useState<number>();

   const authStore = useContext(AuthContext);
   const spinnerService = useLoadingSpinner();
   const {refreshToken} = useApiService();
   const navigate = useNavigate();

   const fadeInComponent = createFadeStyles(750, 100);

   const skillMatchTransition = useTransition(skillMatch, {
      from: { transform: 'translateY(5%)', opacity: 0 },
      enter: { transform: 'translateY(0%)', opacity: 1 },
      config: { duration: 500 }
   });

   const toolCoverageTransition = useTransition(toolCoverage, {
      from: { transform: "scale(0.975)", opacity: 0 },
      enter: { transform: "scale(1)", opacity: 1 },
      config: { duration: 500 }
   });


   useEffect(() => {
      setSkills(props.skills);
   }, [props.skills]);

   return (
      <>
         <div className={style['workshop-component']}>
            <animated.div className="workshop-wrapper" style={fadeInComponent}>
               <button className="button button-tertiary" onClick={() => navigate("/augmentation/augmentation-report")}>
                  <i className="fa-solid fa-arrow-left-long"></i>
                  <span>Go back</span>
               </button>
               <div className="header">
                  <h1 className="header-1">Workshop</h1>
                  <p>We’ve extracted your skills based on your responses. You can see how many people across the
                     platform share each skill and how many of your fellow workshop participants have it, too. Refresh a
                     few times at the start to see if new attendees have joined and updated the numbers.</p>
               </div>
               <main className="workshop-body">
                  <div className="workshop-skills">
                     <div className="header">
                        <h2 className="header-2">Your Skills, Discovered</h2>
                        <p>Now, pick a skill to master and unlock your potential! For example, if fundraising became
                           your superpower, how would that make you unstoppable? Choose a skill where you already have some
                           strength or a natural inclination—it’ll help you build momentum faster.</p>
                     </div>

                     <div className="workshop-skills-list">
                        {
                           skills.filter(s => !s.isDeleted).map(skill => {
                              return (
                                 <div className={`custom-light-skill action ${skill.skillId === dataLoading && "loading"} ${skill.skillId === skillMatch?.skillId && "selected"}`}
                                      key={skill.skillId}
                                      onClick={() => !dataLoading && skill.skillId === skillMatch?.skillId ? closeInformation(true) : getSkillMatch(skill.skillId, skill.skillName, authStore.userData.accessToken)}>
                                    <span className={"skill-title"}>{capitalizeFirstLetter(skill.skillName)}</span>
                                    <Tooltip tooltipId={skill.skillId.toString() + "_workshopUsers"} place={"top"}
                                             className={"custom-tooltip"} content={<div>Total workshop users</div>}>
                                       <div className="users">
                                          <span>{skill.totalWorkshopUsers || 0}</span>
                                          <i className="fa-solid fa-user-gear"></i>
                                       </div>
                                    </Tooltip>
                                 </div>
                              )
                           })
                        }
                     </div>

                  </div>
                  {
                     skillMatchTransition((style, item) => (
                        item &&
                        <animated.div style={style} className="skill-match">
                           <div className="header-button">
                              <h2 className="header-2">Skill: {capitalizeFirstLetter(item.skillName)}</h2>
                              <button className="button button-tertiary" onClick={() => closeInformation(true)}>Close</button>
                           </div>
                           {
                              item.tools.length > 0 ?
                                 <div className="wrapper">
                                    <div className="header">
                                       <h3 className="header-3">Explore Tools</h3>
                                       <p>Clicking on the tool information button will open a feedback form to learn more about its features and share your thoughts, while clicking on the tool itself will reveal which of your extracted skills are linked to it, along with other related skills that you haven’t unlocked yet!</p>
                                    </div>
                                    <div className="tools">
                                       {
                                          item.tools.map(tool => {
                                             return (
                                                <div
                                                   className={`custom-blue-tool ${tool.toolId === dataLoadingTool && "loading"} ${tool.toolId === toolCoverage?.toolId && "selected"}`}
                                                   key={tool.toolId}>
                                                   <span>{tool.name}</span>
                                                   <i className="fa-solid fa-circle-plus"
                                                      onClick={() => !dataLoadingTool && tool.toolId === toolCoverage?.toolId ? closeInformation(false) : getToolCoverage(tool.toolId, tool.name, authStore.userData.accessToken)}></i>
                                                   <i className="fa-solid fa-circle-info" onClick={e => {
                                                      if (!dataLoadingTool) {
                                                         e.stopPropagation();
                                                         props.showToolModal(tool);
                                                   }}}></i>
                                                </div>
                                             )
                                          })
                                       }
                                    </div>
                                    {
                                       toolCoverageTransition((style, item) => (
                                          item && <animated.div style={style} className="tool-info">
                                             <div className="header-button">
                                                <h3
                                                   className="header-3">Tool: {capitalizeFirstLetter(item.toolName)}</h3>
                                                <button className="button button-tertiary"
                                                        onClick={() => closeInformation(false)}>Close
                                                </button>
                                             </div>
                                             <div className="tool-main">
                                                {
                                                   item.linkedSkills.length > 0 &&
                                                   <div className="info-wrapper">
                                                      <h3 className="header-3">Your Skills Related to This Tool</h3>
                                                      <div className="tool-skills">
                                                         {
                                                            item.linkedSkills.length > 0 && item.linkedSkills.map(s =>
                                                               <div className="custom-light-skill"
                                                                    key={s}>{capitalizeFirstLetter(s)}</div>)
                                                         }
                                                      </div>
                                                   </div>
                                                }
                                                {
                                                   item.missingSkills.length > 0 &&
                                                   <div className="info-wrapper">
                                                      <h3 className="header-3">Potential Skills Related to This
                                                         Tool</h3>
                                                      <div className="tool-skills">
                                                         {
                                                            item.missingSkills.map(s => <div
                                                               className="custom-light-skill"
                                                               key={s}>{capitalizeFirstLetter(s)}</div>)
                                                         }
                                                      </div>
                                                   </div>
                                                }
                                             </div>
                                          </animated.div>
                                       ))
                                    }
                                 </div> :
                                 <div className="wrapper">
                                    <p>This skill might not lead to the most exciting opportunities right now. Consider
                                       checking out other skills that can connect you with valuable tools!</p>
                                 </div>
                           }
                           {
                              item.tools.length > 0 && item.neighbours.length > 0 &&
                              <div className="wrapper">
                                 <div className="header">
                                    <h3 className="header-3">Connect with Your Peers</h3>
                                    <p>Discover who else in your workshop group shares your skill! Collaborate, share
                                       insights, and learn from one another as you work towards mastering your chosen
                                       area.
                                       Building connections can enhance your learning experience and help you grow
                                       together!</p>
                                 </div>
                                 <div className="people">
                                    {
                                       item.neighbours.map((neighbour, i) => {
                                          return (
                                             <div className="neighbour" key={i}>
                                                {neighbour}
                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                              </div>
                           }
                        </animated.div>
                     ))
                  }
               </main>
            </animated.div>
         </div>
         {
            spinnerService.spinner
         }
      </>
   );

   function closeInformation(skillMatch: boolean) {
      if (skillMatch) {
         setSkillMatch(undefined);
         setToolCoverage(undefined);
      } else {
         setToolCoverage(undefined);
      }
   }

   function getSkillMatch(skillId: number, skillName: string, accessToken: string) {
      const getSkillMatchURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/skill-match';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         skillId
      }

      setDataLoading(skillId);

      axios
         .post(getSkillMatchURL, data, {headers})
         .then((response$: AxiosResponse<SkillMatchDto>) => {
            setSkillMatch(response$.data);
            setToolCoverage(undefined);
            setDataLoading(undefined);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getSkillMatch(skillId, skillName, response$.data.accessToken);
                  })
            } else {
               setDataLoading(undefined);
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function getToolCoverage(toolId: number, toolName: string, accessToken: string) {
      const getToolCoverageURL = process.env.REACT_APP_PUBLIC_URL + '/augmentation/tool-coverage';

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         toolId
      }

      setDataLoadingTool(toolId);

      axios
         .post(getToolCoverageURL, data, {headers})
         .then((response$: AxiosResponse<ToolCoverageDto>) => {
            setToolCoverage(response$.data);
            setDataLoadingTool(undefined);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     getToolCoverage(toolId, toolName, response$.data.accessToken);
                  })
            } else {
               setDataLoadingTool(undefined);
               showNotification('warning', error$.response.data.message);
            }
         })
   }
}

export default WorkshopComponent;