import styles from './NotificationCenter.module.scss';
import NotificationItem from "./NotificationItem/NotificationItem";
import React, {useContext, useEffect, useState} from "react";
import NotificationContext from "../../../../store/notification-context";
import useApiService from "../../../../services/api.service";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import AuthContext from "../../../../store/auth-context";
import {useNavigate} from "react-router-dom";
import {NotificationCenterItem} from "react-toastify/addons/use-notification-center";
import {Notification} from "../../../../types/Notification";
import {Badge} from "@mui/material";
import {compareDates} from "../../../../utils/compare-dates";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import {animated, useTransition} from 'react-spring';

const NotificationCenter: React.FC<{}> = (props) => {
   const notificationStore = useContext(NotificationContext);
   let notificationsByDate = new Map<string, NotificationCenterItem<Notification>[]>();

   const [notificationList, setNotificationList] = useState(notificationStore.notificationList);
   const [showNotificationBody, setShowNotificationBody] = useState(false);
   const [filterState, setFilterState] = useState<number>(0); // 0 - all , 1 - read, 2 - unread

   const transitionNotifications = useTransition(showNotificationBody, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 200 },
   });

   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();
   const authStore = useContext(AuthContext);
   const navigate = useNavigate();

   notificationList.filter(n => {
      if (filterState === 0) {
         return true;
      }
      if (filterState === 1) {
         return n.data!.read;
      }
      if (filterState === 2) {
         return !n.data!.read;
      }
   }).forEach(n => {
      let date = new Date(n.data!.dateCreated);
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString();
      let day = (date.getDate()).toString();

      if (month.length < 2) {
         month = "0" + month;
      }
      if (day.length < 2) {
         day = "0" + day;
      }
      let dateString = `${year}-${month}-${day}`;

      if (notificationsByDate.has(dateString)) {
         notificationsByDate.get(dateString)?.push(n);
      } else {
         notificationsByDate.set(dateString, [n]);
      }
   })

   useEffect(() => {
      setNotificationList(notificationStore.notificationList);
   }, [notificationStore.notificationList]);

   useEffect(() => {
      if (showNotificationBody) {
         document.addEventListener('click', onClick);
      }
      return () => document.removeEventListener('click', onClick);
   }, [showNotificationBody]);

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   return (
      <div className={styles['main-wrapper']}>
         <div className={'notification-bell'}>
            <div onClick={toggleNotificationBody} className={`notification-button ${showNotificationBody ? 'active' : ''}`}>
               {
                  notificationStore.count > 0 ?
                     <Badge sx={{
                        "& .MuiBadge-badge": {
                           color: "lightgreen",
                           backgroundColor: "#3146C1",
                           border: '1px solid #EAECF0',
                        }
                     }} className={'dot-badge'} overlap={'circular'} color="secondary" variant="dot">
                        <i className="fa-regular fa-bell"></i>
                     </Badge>
                     : <i className={`fa-regular fa-bell`}></i>
               }
            </div>
         </div>
         {
            transitionNotifications((style, item) => (
                item &&
                <animated.div className='notification-center' style={style}>
                   <div className={'nc-wrapper'}>
                      <div className={'header'}>
                         <h2 className={'header-2'}>Notifications</h2>
                         <button onClick={() => markAllAsReadNotification(authStore.userData.accessToken)}
                                 className={'button button-tertiary'}>Read all
                         </button>
                      </div>
                      <div className={'body'}>
                         {Array.from(notificationsByDate.keys()).length > 0 &&
                             sortDatesOfNotification().map((date, index) => {
                                return (
                                    <div key={date.concat(`_${index}`)}>
                                       <div className={'day'}>{checkDate(date)}</div>
                                       {sortNotifications(date).map((notification, index) => {
                                          return <NotificationItem key={notification.id + `_${index}`}
                                                                   notification={notification} markAsReadNotification={
                                             (id: number, notificationId: number, type: number, projectId?: number) => markAsReadNotification(id, notificationId, type, authStore.userData.accessToken, projectId)}
                                                                   removeNotification={
                                                                      (id: number, notificationId: number) => removeNotification(id, notificationId, authStore.userData.accessToken)}/>
                                       })}
                                    </div>
                                )
                             })
                         }
                         {Array.from(notificationsByDate.keys()).length === 0 &&
                             <div className={'no-notification-message'}>
                                <div className={'title'}>👻 Too-da-loo!</div>
                                <div>Nothing happened for the last 30 days.</div>
                             </div>
                         }
                      </div>
                      <div className={'footer-center'}>
                         <span>Filter: </span>
                         <button onClick={setRead}
                                 className={`button button-tertiary un-read-button u-margin-right-xs ${filterState === 1 ? 'active' : ''}`}>Read
                         </button>
                         <button onClick={setUnread}
                                 className={`button button-tertiary un-read-button ${filterState === 2 ? 'active' : ''}`}>Unread
                         </button>
                         <button onClick={() => removeAll(authStore.userData.accessToken)}
                                 className={'button button-tertiary delete-all-button'}>Delete all
                         </button>
                      </div>
                   </div>
                </animated.div>
            ))
         }
         {
            spinnerService.spinner
         }
      </div>
   );

   function checkDate(date: string): string {
      const todayDate = new Date().getDate();
      const todayMonth = new Date().getMonth() + 1;

      const notificationsDate = +date.split('-')[2];
      const notificationsMonth = +date.split('-')[1];

      if (todayDate === notificationsDate && todayMonth === notificationsMonth) {
         return 'Today';
      }

      if ((todayDate - 1) === notificationsDate && todayMonth === notificationsMonth) {
         return 'Yesterday';
      }

      return date;
   }

   function sortDatesOfNotification() {
      return Array.from(notificationsByDate.keys()).sort((k1, k2) => {
         return Date.parse(k1) > Date.parse(k2) ? -1 : 1
      });
   }

   function sortNotifications(date: string) {
      return notificationsByDate.get(date)!.sort((not1, not2) => {
         const date1 = not1.data!.dateCreated;
         const date2 = not2.data!.dateCreated;
         return compareDates(date1, date2);
      });
   }

   function setRead() {
      if (filterState === 1) {
         setFilterState(0);
         return;
      }
      setFilterState(1);
   }

   function setUnread() {
      if (filterState === 2) {
         setFilterState(0);
         return;
      }
      setFilterState(2);
   }

   function initializeComponent(accessToken: string) {

      if (notificationStore.setAuthStoreNotifications()) {
         return;
      }

      notificationStore.getNotifications(accessToken)
          .then(() => {
             return;
          })
          .catch((error$: ErrorResponseDto) => {
             if (error$.response.data.message === 'Unauthorized') {
                // Get new Access Token
                refreshToken(authStore.userData.refreshToken)
                    .then((response$: any) => {
                       authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                       initializeComponent(response$.data.accessToken);
                    })
             } else {
                showNotification('warning', error$.response.data.message);
             }
          })
   }

   function markAllAsReadNotification(accessToken: string) {
      notificationStore.markAllAsReadNotifications(accessToken)
          .then(() => {
             return
          })
          .catch((error$: ErrorResponseDto) => {
             if (error$.response.data.message === 'Unauthorized') {
                // Get new Access Token
                refreshToken(authStore.userData.refreshToken)
                    .then((response$: any) => {
                       authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                       markAllAsReadNotification(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function markAsReadNotification(id: number, notificationId: number, type: number, accessToken: string, projectId?: number) {
      if (type === 1) {
         spinnerService.createSpinner();
      }
      notificationStore.markAsReadNotification(id, notificationId, accessToken)
         .then(() => {
            if (type === 0) {
               // clicked on badge, clicked on removed notification
               return;
            } else if (type === 1) {
               // clicked on body , go to skills wallet or go to project panel for project with project id
               if (projectId) {
                  // clicked on notification for project
                  navigate('/manager/projects/' + projectId + '/manager-dashboard', {replace: true});
               } else {
                  // clicked on working notification, learning notification
                  navigate('/employee/skills-wallet', {replace: true});
               }
            }
            else if (type === 2) {
               // clicked on body, clicked on archived notification
               navigate('/manager/projects/all-projects', {replace: true});
            }
            setShowNotificationBody(false);
            setFilterState(0);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     markAsReadNotification(id, notificationId, type, response$.data.accessToken, projectId);
                  })
            } else {
               spinnerService.removeSpinner();
            }
         })
   }

   function removeAll(accessToken: string) {
      notificationStore.clearAllNotifications(accessToken)
         .then(() => {
            return
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     removeAll(response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function removeNotification(id: number, notificationId: number, accessToken: string) {
      notificationStore.removeNotification(id, notificationId, accessToken)
         .then(() => {
            return
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     removeNotification(id, notificationId, response$.data.accessToken);
                  })
            } else {
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function onClick(e: any) {
      if (Array.from(document.querySelector('.notification-button')!.querySelectorAll("*")).filter(el => el == e.target).length === 0 && e.target != document.querySelector('.notification-button')) {
         if (Array.from(document.querySelector('.nc-wrapper')!.querySelectorAll("*")).filter(el => el == e.target).length === 0 && e.target != document.querySelector('.nc-wrapper')) {
            setShowNotificationBody(false);
            setFilterState(0);
         }
      }
   }

   function toggleNotificationBody() {
      setShowNotificationBody(prevState => !prevState);
      setFilterState(0);
   }
}

export default NotificationCenter;