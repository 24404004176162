import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';

import "./styles/styles.scss";

import { AuthContextProvider } from './store/auth-context';
import App from "./App";
import { PostHogProvider} from 'posthog-js/react'

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const root = ReactDOM.createRoot(document.getElementById("app-root") as HTMLElement);
root.render(
	<BrowserRouter>
		<AuthContextProvider>
			{
				process.env.REACT_APP_PUBLIC_URL === "https://dev-api-prod-gke.skillswallet.io" ?
					<PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY} options={options}>
						<App />
					</PostHogProvider> :
					< App />
			}
		</AuthContextProvider>
	</BrowserRouter>
);