import React, {MouseEventHandler} from "react";
import styles from './TermsConditionsModal.module.scss';
import {TermsConditions} from "../../../utils/terms-conditions";
import {animated} from "react-spring";

const TermsConditionsModal: React.FC<{cancelCallBack: MouseEventHandler<HTMLButtonElement>, style: any}> = props => {

   return (
      <>
         <animated.div className={styles['terms-conditions-modal']} style={props.style}>
            <div className="card-terms-modal">
               <div className="card-header">
                  <div className="card-header-title">
                     <h1 className="card-header-title-text header-1">Terms & Conditions Of Use</h1>
                     <div className="card-header-title-time">
                        <img src="/assets/images/global-icons/last-updated-icon.svg" alt="last updated icon"/>
                        <span className="card-header-title-time-text">Last Updated: July 31, 2024</span>
                     </div>
                  </div>
                  <h2 className="card-header-subtitle header-2">Your Agreement</h2>
               </div>
               <div className="card-terms-body">
                  <div className="terms-paragraph">
                     {
                        TermsConditions.map((item, index) => {
                           return (
                              <div key={index} className="terms-paragraph-section">
                                 <div className="terms-paragraph-section-container">
                                    {item.sectionTitle && <h2 className="terms-paragraph-section-container-title">{item.sectionTitle}</h2>}
                                    <div className="terms-paragraph-section-container-content">{item.sectionContent}</div>
                                    {
                                       item.sectionSubContent &&
                                       <ul className={'terms-paragraph-section-container-list'}>
                                          {
                                             item.sectionSubContent.map((item, index) => {
                                                return (
                                                   <li key={index} className={'terms-paragraph-section-container-list-item'}>
                                                      {item.title && <span className={'terms-paragraph-section-container-list-item-title'}>{item.title + ' '}</span>}
                                                      {item.body}
                                                   </li>
                                                );
                                             })
                                          }
                                       </ul>
                                    }
                                    {
                                       item.sectionAfterContent &&
                                       <div className={'terms-paragraph-section-container-after'}>{item.sectionAfterContent}</div>
                                    }
                                 </div>
                              </div>
                           )
                        })
                     }
                  </div>
               </div>
               <div className="card-terms-footer">
                  <button onClick={props.cancelCallBack} className={'button button-secondary'}>Close</button>
               </div>
            </div>
         </animated.div>
      </>
   )
}

export default TermsConditionsModal;