import React, {useContext, useEffect, useRef, useState} from "react";
import style from './RegisterPage.module.scss';
import {RegisterData, SendRegisterData} from "../../types/LoginRegisterData";
import {NavLink, useSearchParams} from "react-router-dom";
import SpinnerButton from "../../ui/SpinnerButton/SpinnerButton";
import {ErrorResponseDto} from "../../types/ErrorData";
import AuthContext from "../../store/auth-context";
import useApiService from "../../services/api.service";
import TermsConditionsModal from "./TermsConditionsModal/TermsConditionsModal";
import {createFadeStyles} from "../../utils/springStyles";
import {animated, useTransition} from "react-spring";

const RegisterPage: React.FC<{}> = props => {

   const [formData, setFormData] = useState<RegisterData>({
      name: '',
      username: '',
      company: '',
      password: '',
      registerCode: '',
   });

   const [formErrorData, setFormErrorData] = useState({
      name: false,
      company: false,
      password: false,
      username: false,
      registerCode: false,
   });

   const [typePassword, setTypePassword] = useState(true);
   const [searchParams] = useSearchParams();
   const [error, setError] = useState('');
   const [dataLoading, setDataLoading] = useState(false);
   const [checked, setChecked] = useState(false);
   const [showTerms, setShowTerms] = useState(false);
   const fadeInComponent = createFadeStyles(750);
   const fullNameRef = useRef<HTMLInputElement>(null);

   const transitionModal = useTransition(showTerms, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 200 },
   });

   const authStore = useContext(AuthContext);
   const { tryRegister } = useApiService();

   useEffect(() => {
      const registerCode = searchParams.get("code");
      const email = searchParams.get("email")?.replace(" ", "+");

      registerCode && email && setFormData({
         ...formData,
         registerCode,
         username: email
      });

      const timer = setTimeout(() => {
         if (fullNameRef.current) {
            fullNameRef.current.click();
            fullNameRef.current.focus();
         }
      }, 250);

      return () => clearTimeout(timer);

   }, []);


   return (
      <>
         <div className={style['register-page']}>

            <animated.div className="page-wrapper" style={fadeInComponent}>

               <section className={'form-section'}>

                  <div className="header-wrapper">
                     <h1 className="header-1">Create an account!</h1>
                     <p>You will need your own personal account with TeamLift to make sure the results are fine tuned to your unique situation.</p>
                  </div>

                  <div className="form-wrapper">

                     <form className={'custom-form'}>

                        <div className="form-child">
                           <label htmlFor="name" className="custom-label">Name</label>
                           <input value={formData.name} onChange={handleChange} type="text"
                                  className={`custom-input ${formErrorData.name && "error"}`}
                                  id="name" name="name" autoComplete="name"
                                  placeholder='Enter your full name (e.g., John Doe)'
                                  required ref={fullNameRef} />
                        </div>

                        <div className="form-child">
                           <label htmlFor="username" className="custom-label">Email</label>
                           <input disabled value={formData.username} onChange={handleChange} type="email"
                                  className={`custom-input ${formErrorData.username && "error"}`}
                                  id="username" name="username" autoComplete={'off'}
                                  placeholder='Enter your email'/>
                        </div>

                        <div className="form-child">
                           <label htmlFor="company" className="custom-label">Company</label>
                           <input value={formData.company} onChange={handleChange} type="text"
                                  className={`custom-input ${formErrorData.company && "error"}`}
                                  id="company" name="company"
                                  placeholder='Enter your company'/>
                        </div>

                        <div className="form-child">
                           <label htmlFor="password" className="custom-label">Password</label>
                           <div className="custom-input-wrapper">
                              <input value={formData.password} onChange={handleChange}
                                     type={typePassword ? 'password' : 'text'}
                                     name="password" className={`custom-input ${formErrorData.password && "error"}`}
                                     id="password" autoComplete='current-password'
                                     placeholder='Enter your password'/>
                              <span className={'eye'} onClick={() => setTypePassword(prevState => !prevState)}>
                                 <i className={typePassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}></i>
                              </span>
                           </div>
                        </div>

                        <div className="form-child check">
                           <div className={`checkbox-dark ${checked ? 'checked' : ''}`} onClick={() => setChecked(prevState => !prevState)}><i className="fa-solid fa-check"></i></div>
                           <div className={'checkbox-label'}>I agree to the <button className="button button-tertiary terms" type={'button'} onClick={() => setShowTerms(true)}>Terms of Service</button>.</div>
                        </div>

                        {
                           error !== "" &&
                            <div className="custom-error-message">
                               <div className="title">
                                  <i className="fa-solid fa-circle-exclamation"></i>
                                  <h4 className="header-4">Something went wrong</h4>
                               </div>
                               <p className={"error-body"}>{error}</p>
                            </div>
                        }

                     </form>

                     <SpinnerButton title={'Create account'} dataLoading={dataLoading} buttonType={'button'}
                                    cssClasses={'button button-primary'} onClickCallback={checkForm}/>

                     <div className="forgot-password">
                        <NavLink to={'/login'} className='button button-tertiary forgot-password-link'>Already have an
                           account? Log in</NavLink>
                     </div>

                  </div>

               </section>

               <section className="teamo-section">
                  <img src="/assets/images/ai-impact-score-icons/teamo-thinking-blue-icon.svg"
                       alt="Teamo blue thinking" className={"teamo-image"}/>
               </section>

            </animated.div>
         </div>
         {
            transitionModal((style, item) => (
               item ? <TermsConditionsModal cancelCallBack={() => setShowTerms(false)} style={style} /> : null
            ))
         }
      </>
   )

   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const {name, value} = e.target;
      setFormData({
         ...formData,
         [name]: value
      })
   }

   function checkForm() {
      let name = formData.name.trim().length === 0;
      let company = formData.company.trim().length === 0;
      let username = formData.username.trim().length === 0;
      let password = formData.password.trim().length < 6;
      let registerCode = formData.registerCode.trim().length === 0;

      setFormErrorData({name, username, company, password, registerCode});
      if (name || username || company || password || registerCode) {
         if (password) {
            setError("Password must be at least 6 characters long.");
            if (!checked) {
               setError(prevState => prevState + " You must agree to the terms of service before proceeding.");
            }
         } else {
            setError("");
         }
         return;
      }

      if (!checked) {
         setError(prevState => prevState + " You must agree to the terms of service before proceeding.");
         return;
      }

      const data: SendRegisterData = {
         data: formData
      }

      submitRegisterForm(data);
   }

   // Submit function for register
   function submitRegisterForm(data: SendRegisterData) {
      setDataLoading(true);

      tryRegister(data)
         .then((res$: any) => {
            setError("");
            setDataLoading(false);
            authStore.login(res$['data']);
         })
         .catch((error$: ErrorResponseDto) => {
            setDataLoading(false);
            if (error$.response.data && error$.response.data.statusCode === 400 || error$.response.data && error$.response.data.statusCode === 404) {
               setError(error$.response.data.message);
            }
            else {
               setError("Unable to login!");
            }
         });
   }
}

export default RegisterPage;