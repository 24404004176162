import React, {useContext} from "react";
import {AugmentationReportTopAiToolsDto, QuestionnaireSessionStatusEnum} from "../../../../../types/AugmentationData";
import style from './TopToolsPage.module.scss';
import {animated} from "react-spring";
import {createTransition} from "../../../../../utils/springStyles";
import AuthContext from "../../../../../store/auth-context";
import {Tooltip as MuiTooltip} from "@mui/material";
import useResponsiveComponent from "../../../../../ui/ResponsiveComponent/ResponsiveComponent";

const TopToolsPage: React.FC<{
    topToolsData: AugmentationReportTopAiToolsDto
}> = props => {

    const transitionLoadingPage = createTransition(props.topToolsData.sessionStatus !== QuestionnaireSessionStatusEnum.FINISHED, 500, 0);
    const transitionTopToolsPage = createTransition(props.topToolsData.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED, 500, 0);
    const pageWidth = useResponsiveComponent();

    const authStore = useContext(AuthContext);

    return (
        <>
            <div className={style['top-tools-page']}>
                {
                    props.topToolsData.sessionStatus === QuestionnaireSessionStatusEnum.FINISHED &&
                        transitionTopToolsPage((style, isActive) => (
                            isActive && (
                                <animated.div style={style} className={"pi-unfinished-main"}>
                                    <div className="text-container">
                                        <div className="wrapper top-tools">
                                            <h1 className="header-1">Top 10 Personalized AI Tools</h1>
                                            <p className="surface-30">Explore these AI tools tailored to automate your
                                                tasks, streamline your workflow and save your time!</p>
                                            <div className="tools-wrapper">
                                                {
                                                    props.topToolsData!.toolsList.map(t => {
                                                        return (
                                                            <div key={t.tool.toolId} className={"tool-wrapper"}>
                                                                <div className="priority">{t.priority}</div>
                                                                <div className="title">
                                                                    {
                                                                        (t.tool.name.length > 20 && pageWidth.windowWidth <= 768) || (t.tool.name.length > 25 && pageWidth.windowWidth > 768) ?
                                                                            <>
                                                                                <MuiTooltip title={t.tool.name}>
                                                                                    <span>{t.tool.name.slice(0, pageWidth.windowWidth <= 768 ? 20 : 25).trim()}...</span>
                                                                                </MuiTooltip>
                                                                            </> : t.tool.name
                                                                    }
                                                                </div>
                                                                <a className={`link ${t.websiteUrl ? "" : "no-link"}`}
                                                                   target={"_blank"} href={t.websiteUrl || ""}><i
                                                                    className="fa-solid fa-link"></i></a>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            <div className="tt-footer">
                                                <img src="/assets/images/global-icons/teamlift-logo-white.svg"
                                                     alt="TeamLift logo"/>
                                                <button className="button button-tertiary" onClick={authStore.logout}><i
                                                    className="fa-solid fa-arrow-right-from-bracket"></i> Log out
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </animated.div>
                            )
                            )
                        )
                }

                {
                    transitionLoadingPage((style, isActive) => (
                        isActive && (
                            <animated.div style={style} className={"loading-screen"}>
                                {
                                    props.topToolsData.sessionStatus === QuestionnaireSessionStatusEnum.PROCESSING ?
                                        <>
                                            <img src="/assets/images/ai-impact-score-icons/teamo-waiting-icon.svg"
                                                 alt="Teamo waiting icon"/>
                                            <h2 className="header-2">Just a moment, Teamo is analyzing your data.</h2>
                                            <div className="u-margin-top-m">
                                                <p>Our AI is analyzing your data.</p>
                                                <p>Your personalized insights will be ready in just a moment!</p>
                                            </div>
                                        </> :
                                        <>
                                            <img src="/assets/images/ai-impact-score-icons/teamo-die-icon.svg"
                                                 alt="Teamo die icon"/>
                                            <h2 className="header-2">Oops! There was an error.</h2>
                                            <div className="u-margin-top-m">
                                                <p>We're sorry, we ran into an issue.</p>
                                                <p>Please reach out to our support team at <a href="mailto:support@teamlift.co">support@teamlift.co</a> and we'll help you out.
                                                </p>
                                            </div>
                                        </>
                                }
                                <button className="button button-primary" onClick={authStore.logout}><i className="fa-solid fa-arrow-right-from-bracket"></i> Log out</button>
                            </animated.div>
                        )
                    ))
                }
            </div>
        </>
    )
}

export default TopToolsPage;