import React from "react";
import style from './HowItWorksPage.module.scss';
import useResponsiveComponent from "../../../../../ui/ResponsiveComponent/ResponsiveComponent";
import {animated, useSpring} from "react-spring";

const HowItWorksPage: React.FC<{
   changeSection: () => void,
}> = props => {

   const steps = [
      {
         stepTitle: 'Exploring',
         stepContent: 'At first you start Exploring AI without any clear goal. Is that how you found Teamo?'
      },
      {
         stepTitle: 'Awareness',
         stepContent: 'Then you become Aware of AI tools that could be useful in your work.'
      },
      {
         stepTitle: 'Active Learning',
         stepContent: 'After which you start Actively learning AI tools to augment your skills.'
      },
      {
         stepTitle: 'Mastery',
         stepContent: 'Which leads you to Mastery of AI tools and prepares you for the future of work!'
      }
   ];

   const width = useResponsiveComponent().windowWidth;

   const componentTransition = useSpring( {
      from: { transform: 'translateX(-3%)', opacity: 0 },
      to: { transform: 'translateX(0%)', opacity: 1 },
      config: { duration: 500 },
   });

   return (
      <>
         <animated.div className={style['how-it-works-page']} style={componentTransition}>
            <section className="info-section">
               <h1 className="header-1">How this works
                  {
                     width <= 1000 && width > 768 &&
                     <img src="/assets/images/ai-impact-score-icons/teamo-okay-icon.svg" alt="Teamo okay icon"/>
                  }
               </h1>
               <p className="text-surface-10">Learning goes through stages, and Teamo will guide you through them—quickly and easily!</p>
               <div className="steps-section">
                  {
                     steps.map((step, index) => {
                        return (
                           <div className="step" key={index}>
                              <div className="step-title">Step {index + 1}: {step.stepTitle}</div>
                              <p className="step-explanation text-surface-40">{step.stepContent}</p>
                           </div>
                        );
                     })
                  }
               </div>
               <button className="button button-primary" onClick={props.changeSection}>Create your awareness</button>
            </section>
            {
               width > 1000 &&
               <section className="teamo-section">
                  <img src="/assets/images/ai-impact-score-icons/teamo-okay-icon.svg" alt="Teamo okay icon"/>
               </section>
            }
         </animated.div>
      </>
   )
}

export default HowItWorksPage;