import React, {useEffect, useRef, useState} from "react";
import style from './EmployeeQuestionPage.module.scss';
import {QuestionTypeEnum, RegisterQuestionDto} from "../../../../../types/EmployeeOnboardRegisterData";
import {animated, useTransition} from "react-spring";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";
import {capitalizeFirstLetter} from "../../../../../utils/capitalize-first-letter";
import {SearchedSkillDto} from "../../../../../types/SearchComponentData";
import SelectComponent from "../../../UploadDataPage/UploadPersonalDataComponent/AddManuallySkillsComponent/SelectComponent/SelectComponent";
import responsiveComponent from "../../../../../ui/ResponsiveComponent/ResponsiveComponent";
import ModalComponent from "../../../../../ui/ModalComponent/ModalComponent";
import {Tooltip} from "@mui/material";
import {showNotification} from "../../../../../ui/Toast/ToastNotification";

const EmployeeQuestionPage: React.FC<{
   question: RegisterQuestionDto,
   saveAnswer: (questionId: number, answer: string, goBack: boolean) => void,
   dataLoading: false | 'back' | 'next',
   lastQuestion: boolean,
}> = props => {

   const [skills, setSkills] = useState<SearchedSkillDto[]>([]);
   const [inputValue, setInputValue] = useState("");
   const [savedDelimiterAnswers, setSavedDelimiterAnswers] = useState<string[]>([]);
   const [chosenAnswers, setChosenAnswers] = useState<{value: string, selected: boolean}[]>([]);
   const [selectedSkill, setSelectedSkill] = useState<SearchedSkillDto>();
   const [selectedAnswer, setSelectedAnswer] = useState("");
   const width = responsiveComponent().windowWidth;
   const lastSkillRef = useRef<HTMLDivElement>(null);
   const delimiter = " \n ";

   useEffect(() => {
      if (props.question.answerType === QuestionTypeEnum.TEXT) {
         setSkills([]);
         setInputValue("");
         setSavedDelimiterAnswers(props.question.answer && props.question.answer.trim() ? props.question.answer.trim().split(delimiter).filter(a => a.trim()) : []);
         setChosenAnswers([]);
      }
      else {
         setInputValue('');
         setSavedDelimiterAnswers([]);
         setSkills(props.question.skills ? props.question.skills.map(s => ({value: s.aiId, label: s.name, description: s.description})) : []);
         setChosenAnswers([]);
      }
   }, [props.question]);

   useEffect(() => {
      if (lastSkillRef.current) {
         lastSkillRef.current.scrollIntoView({ behavior: 'smooth' });
      }
   }, [skills, savedDelimiterAnswers]);

   const questionTransition = useTransition(props.question, {
      from: { transform: 'translateX(-5%)', opacity: 0 },
      enter: { transform: 'translateX(0%)', opacity: 1 },
      config: { duration: 500 },
      reset: false
   });

   const transitionModal = useTransition(selectedSkill || selectedAnswer, {
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0 },
      config: { duration: 200 },
   });

   return (
      <>
         <div className={style['employee-question-page']}>
            {
               questionTransition((styles, _item) => (
                  <animated.div className={style["name-page"]} style={styles}>
                     <h3 className="header-3 u-margin-bottom-xl question-title">{props.question.questionText}</h3>

                     <div className={`form ${props.question.answerType === QuestionTypeEnum.SKILL_SELECTION ? "skill-selection" : ""}`}>
                        {
                           props.question.answerType === QuestionTypeEnum.TEXT &&
                           <>
                              {
                                 savedDelimiterAnswers.length > 0 &&
                                 <div className="skills-container">
                                    {
                                       savedDelimiterAnswers.map((answer, index) => {
                                          const isLast = index == savedDelimiterAnswers.length - 1;
                                          return (
                                             <div key={index} className={`skill-wrapper`}
                                                  ref={isLast ? lastSkillRef : null}>
                                                <div className="skill-name">{answer.length > 25 ? answer.slice(0, 25) + "..." : answer}</div>
                                                {
                                                   width > 768 ?
                                                      <Tooltip title={answer}>
                                                         <i className="fa-regular fa-circle-question"></i>
                                                      </Tooltip> : <i className="fa-regular fa-circle-question"
                                                                      onClick={() => setSelectedAnswer(answer)}></i>
                                                }
                                                <i className="fa-solid fa-xmark" onClick={() => setSavedDelimiterAnswers(prevState => prevState.filter((_a, i) => i !== index))}></i>
                                             </div>
                                          )
                                       })
                                    }
                                 </div>
                              }

                              <textarea disabled={props.dataLoading !== false} value={inputValue}
                                        placeholder={"Your activity here..."}
                                        onKeyDown={e => {
                                           if (e.key === "Enter") {
                                              e.preventDefault();
                                              addAnswer();
                                           }
                                        }}
                                        onChange={e => setInputValue(e.target.value)}
                                        id={"questionInput"} className={"custom-input"}/>

                              <button className="button button-primary answer-button" disabled={inputValue.trim().length < 2} onClick={addAnswer}>Add activity</button>
                           </>
                        }
                        {
                           props.question.answerType === QuestionTypeEnum.SKILL_SELECTION &&
                           <>
                              {
                                 skills.length > 0 &&
                                 <div className="skills-container">
                                    {
                                       skills.map((skill, index) => {
                                          const isLast = index == skills.length - 1;
                                            return (
                                                <div key={skill.value} className={`skill-wrapper`} ref={isLast ? lastSkillRef : null}>
                                                   <div className="skill-name">{capitalizeFirstLetter(skill.label)}</div>
                                                   {
                                                      width > 768 ?
                                                          <Tooltip title={skill.description}>
                                                             <i className="fa-regular fa-circle-question"></i>
                                                          </Tooltip> : <i className="fa-regular fa-circle-question"
                                                                          onClick={() => setSelectedSkill(skill)}></i>
                                                   }
                                                   <i className="fa-solid fa-xmark"
                                                      onClick={() => selectSkill(skill)}></i>
                                                </div>
                                            )
                                         })
                                      }
                                   </div>
                               }
                               <SelectComponent skillsToBeAdded={skills} addSkillFromSelect={addSkillFromSelect}
                                                menuPlacement={"bottom"}/>
                            </>
                        }
                        <div className={"tips-wrapper"}>
                           <div className="title">
                              <img src="/assets/images/global-icons/lightbulb.svg" alt="Ligth bulb"/>
                              <div>Here’s an example response</div>
                           </div>
                           <p className={'text-surface-30 preferred-answer'}>{props.question.preferredAnswer?.replaceAll("\\n", "\n")}</p>
                        </div>
                     </div>

                     <div className={`buttons-wrapper u-margin-top-xl`}>
                        <SpinnerButton cssClasses={"button button-secondary"}
                                       dataLoading={props.dataLoading === 'back'} title={"Back"}
                                       initialDisabled={props.dataLoading === 'next'}
                                       buttonType={'back'}
                                       onClickCallback={() => saveAnswer(true)} />

                        <SpinnerButton cssClasses={"button button-primary"}
                                       initialDisabled={(savedDelimiterAnswers.length === 0 && skills.length === 0 && chosenAnswers.filter(a => a.selected).length === 0) || props.dataLoading === 'back'}
                                       dataLoading={props.dataLoading === 'next'} title={props.lastQuestion ? "Finish" : "Next"}
                                       buttonType={props.lastQuestion ? 'button' : 'next'}
                                       onClickCallback={() => saveAnswer(false)} />
                     </div>
                  </animated.div>
               ))
            }
            {
               transitionModal((style, skill) => (
                   skill && <ModalComponent style={style}>
                      {
                         typeof skill !== 'string' ?
                            <>
                               <h3 className="header-3">{skill.label}</h3>
                               <p className="color-surface-20">{skill.description}</p>
                            </>
                            :
                            <>
                               <h3 className="header-3">Activity</h3>
                               <p className="color-surface-20">{skill}</p>
                            </>
                      }
                      <button className="button button-primary" onClick={() => {
                         typeof skill !== 'string' ? setSelectedSkill(undefined) : setSelectedAnswer("");
                      }}>Close</button>
                   </ModalComponent>
               ))
            }
         </div>
      </>
   );

   function addAnswer() {
      if (inputValue.trim().length >= 2) {
         if (!savedDelimiterAnswers.find(a => a.toLowerCase().trim() === inputValue.toLowerCase().trim())) {
            setSavedDelimiterAnswers(prevState => [...prevState, inputValue.trim()]);
            setInputValue('');
         }
         else {
            showNotification('warning', 'This activity has already been added.')
         }
      }
   }

   function selectSkill(skill: SearchedSkillDto) {
      setSkills(prevState => prevState.filter(s => s.value !== skill.value));
   }

   function addSkillFromSelect(skill: SearchedSkillDto) {
      setSkills(prevState => [...prevState, skill]);
   }

   function saveAnswer(goBack: boolean) {
      if (props.question.answerType === QuestionTypeEnum.TEXT) {
         let answerString = '';
         savedDelimiterAnswers.forEach((a, i) => {
            i === 0 ? answerString += a : answerString += delimiter + a;
         })
         if (inputValue.trim().length >= 2 && !savedDelimiterAnswers.find(a => a.toLowerCase().trim() === inputValue.toLowerCase().trim())) {
            answerString += delimiter + inputValue.trim();
         }
         props.saveAnswer(props.question.id, answerString, goBack);
      }
      else {
         let skillsString = "";
         skills.forEach(skill => {
            skillsString += skill.value + ";";
         });
         props.saveAnswer(props.question.id, skillsString.substring(0, skillsString.length - 1), goBack);
      }
   }
}

export default EmployeeQuestionPage;