import React, {useContext, useEffect} from "react";
import style from './SuperAdminPage.module.scss';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import WorkshopComponent from "./WorkshopComponent/WorkshopComponent";
import RegisterEmployeesComponent from "./RegisterEmployeesComponent/RegisterEmployeesComponent";
import AuthContext from "../../../store/auth-context";

const SuperAdminPage: React.FC = () => {

   const authStore = useContext(AuthContext);

   useEffect(() => {
      authStore.changePageTitle("Super Admin Panel");
   }, []);

   return (
      <>
         <div className={style['super-admin-page']}>
            <div className="tabs-container">
               <Tabs className={'custom-tabs'} selectedTabClassName={'selected-tab'}>
                  <TabList>
                     <Tab>Registered users</Tab>
                     <Tab>Workshops</Tab>
                  </TabList>
                  <TabPanel>
                     <RegisterEmployeesComponent />
                  </TabPanel>
                  <TabPanel>
                     <WorkshopComponent />
                  </TabPanel>
               </Tabs>
            </div>
         </div>
      </>
   );
}

export default SuperAdminPage;