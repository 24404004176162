export type AiAnnotatingToolsDataDto = {
   toolName: string;
   toolDescription: string;
   category: string;
   skills: AiAnnotatingSkillsDataDto[];
}

export type AiAnnotatingSkillsDataDto = {
   skillAiId: string;
   skillName: string;
   skillDescription: string;
}

export type AnnotatedToolDataRequestDto = {
   toolName: string;
   category: string;
   annotatedSkills: AnnotatedSkillsDataRequestDto[];
   isTool: ToolAnnotationEnum;
   fixedDescription?: string;
   skippedExplanation?: string;
}

export enum ToolAnnotationEnum {
   IS_TOOL = "IS_TOOL",
   NOT_TOOL = "NOT_TOOL",
   SKIP = "SKIP",
}

export type AnnotatedSkillsDataRequestDto = {
   skillAiId: string;
   annotationBool: boolean;
}