import {SynonymDto} from "./Settings";
import {LevelDto} from "./Projects";

export type AugmentationReportDataRepresentationDto = {
   likeableAugmentedSkills: AugmentationSkillsRepresentationDto[];
   likeableNonAugmentedSkills: AugmentationSkillsRepresentationDto[];
   disLikeableAugmentedSkills: AugmentationSkillsRepresentationDto[];
   disLikeableNonAugmentedSkills: AugmentationSkillsRepresentationDto[];
   augmentedSkillsPercent: number;
   likeableAugmentedSkillsPercent: number;
   disLikeableAugmentedSkillsPercent: number;
   nonAugmentedSkillsPercent: number;
   likeableNonAugmentedSkillsPercent: number;
   disLikeableNonAugmentedSkillsPercent: number;
   aiImpactScore: number;
   sessionStatus: QuestionnaireSessionStatusEnum;
   augmentationNotification?: boolean;
   workshopVisibility: boolean;
}

export type AugmentationSkillsRepresentationDto = {
   skill: SkillDto;
   tools?: ToolDto[];
   counterTools?: number;
   isDeleted?: boolean;
}

export type SkillDto = {
   id: number;
   name: string;
   description: string;
   icon: string;
   category: string;
   aiId: string;
   isTool: boolean;
   isAITool: boolean;
   isGeneralSoftSkill: boolean;
   level: LevelDto;
   synonym?: SynonymDto;
   status?: number;
}

export type ToolDto = {
   toolId: number;
   name: string;
   feedback: FeedbackDto | null;
   isTopTool?: boolean;
}

export type ToolInformation = {
   description: string;
   websiteUrl: string | null;
   hotSauce: HotSauceDto | null;
   feedback: FeedbackDto | null;
}

export type HotSauceDto = {
   toolPros: string | null;
   toolCons: string | null;
   toolPricingCategory: string | null;
   toolPricingDescription: string | null;
   toolRating: string | null;
}

export type FeedbackDto = {
   feedback: ToolFeedbackUses;
   startUsingDate?: Date;
}

export enum QuestionnaireSessionStatusEnum {
   OPEN = 'OPEN',
   PROCESSING = 'PROCESSING',
   FINISHED = 'FINISHED',
   FAILED = 'FAILED',
   BE_FAILED = 'BE_FAILED',
}

export enum ToolFeedbackUses {
   ALREADY_USING = 'ALREADY_USING',
   START_USING = 'START_USING',
   IS_NOT_FOR_ME = 'IS_NOT_FOR_ME',
   IMPACTED_MY_WORK = 'IMPACTED_MY_WORK',
   DID_NOT_IMPACTED_MY_WORK = 'DID_NOT_IMPACTED_MY_WORK',
}

// FOR NEW AUGMENTATION REPORT
export type GeneralToolsRepresentationDto = {
   sessionStatus: QuestionnaireSessionStatusEnum;
   workshopVisibility: boolean;
   numberOfSkills?: number;
   numberOfAugSkills?: number;
   topGeneralTools?: GeneralToolsDto[];
   topSpecificTools?: ToolInformationDto[];
}

export type GeneralToolsDto = {
   toolId: number;
   toolName: string;
   focus: string;
   impact: string;
   estimatedUsers: string;
   feedback?: FeedbackDto;
   website?: string;
}

export type ToolInformationDto = {
   id: number;
   name: string;
   description: string;
   websiteUrl: string | null;
   hotSauce: HotSauceDto | null;
   feedback: FeedbackDto | null;
}

// FOR WORKSHOP
export type ExtractedSkillsDto = {
   skillId: number;
   skillName: string;
   totalUsers: number;
   totalWorkshopUsers: number;
   isDeleted?: boolean;
}

export type SkillMatchDto = {
   skillId: number;
   skillName: string;
   tools: ToolDto[];
   neighbours: string[];
}

export type ToolCoverageDto = {
   toolId: number;
   toolName: string;
   linkedSkills: string[];
   missingSkills: string[];
}

export type AugmentationReportTopAiToolsDto = {
   sessionStatus: QuestionnaireSessionStatusEnum;
   toolsList: TopAiToolsDto[];
   counterTools: number;
}

export type TopAiToolsDto = {
   priority: number;
   tool: ToolDto;
   websiteUrl?: string;
}