import React, {useContext, useEffect, useRef, useState} from "react";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {WorkShopAddDto, WorkshopCodeDto, WorkShopUpdateDto} from "../../../../types/SuperAdminData";
import {ErrorResponseDto} from "../../../../types/ErrorData";
import {showNotification} from "../../../../ui/Toast/ToastNotification";
import AuthContext from "../../../../store/auth-context";
import useLoadingSpinner from "../../../../ui/FullPageLoadingSpinner/FullPageLoadingSpinner";
import useApiService from "../../../../services/api.service";
import {TabulatorFull as Tabulator} from "tabulator-tables";
import AddWorkshopModal from "./AddWorkshopModal/AddWorkshopModal";
import {getFormattedTime} from "../../../../utils/get-time";

const WorkshopComponent: React.FC<{}> = props => {

   const [workShopList, setWorkShopList] = useState<WorkshopCodeDto[]>([]);
   const [showModal, setShowModal] = useState(false);
   const [dataLoading, setDataLoading] = useState(false);
   const workShopGrid = useRef<any>();
   const workShopGridRef = useRef<any>();

   const authStore = useContext(AuthContext);
   const {refreshToken} = useApiService();
   const spinnerService = useLoadingSpinner();

   useEffect(() => {
      initializeComponent(authStore.userData.accessToken);
   }, []);

   useEffect(() => {
      workShopGrid.current = new Tabulator(workShopGridRef.current, {
         data: parseWorkShops(workShopList),
         columns: gridColumnsDefinition,
         headerSortClickElement: "icon",
         layout: "fitColumns",
         validationMode: "highlight",
         headerSortElement: function (column, dir) {
            switch (dir) {
               case "asc":
                  return "<span class='material-symbols-outlined'>sort</span>";
               case "desc":
                  return "<span class='material-symbols-outlined'>sort</span>";
               default:
                  return "<span class='material-symbols-outlined'>sort</span>";
            }
         }
      });

      return () => {
         workShopGrid.current.destroy();
      }
   }, [workShopList]);

   const gridColumnsDefinition: any = [
      {title: "Date added", field: "dateAdded", minWidth: 200, headerSortTristate: true},
      {title: "Code", field: "code", minWidth: 200, headerSortTristate: true, validator: ["required", "unique"]},
      {title: "Name", field: "name", minWidth: 200, headerSortTristate: true, validator: ["required"]},
      {title: "Is visible", field: "isVisible", minWidth: 200, headerSortTristate: true},
      {
         title: 'Actions', field: "actionButtons", headerSort: false, minWidth: 200, frozen: true,
         formatter: (cell: any) => {

            let cellData: WorkshopCodeDto = cell.getData();

            let wrapper = document.createElement('div');
            wrapper.classList.add('row-actions');

            // EDIT CODE BUTTON
            let editButton = document.createElement('button');
            editButton.classList.add('button', 'button-tertiary');
            editButton.innerText = `Update to: ${cellData.isVisible ? "Not visible" : "Visible"}`;
            editButton.title = 'Edit this workshop';
            editButton.addEventListener('click', () => {
               updateWorkShop({code: cellData.code, isVisible: !cellData.isVisible}, authStore.userData.accessToken);
            });

            wrapper.appendChild(editButton);

            // DELETE CODE BUTTON
            let button = document.createElement('button');
            button.classList.add('button', 'button-tertiary');
            button.innerText = 'Delete';
            button.title = 'Delete this workshop';
            button.addEventListener('click', () => {
               deleteWorkShop(cellData.code, authStore.userData.accessToken);
            });

            wrapper.appendChild(button);
            return wrapper;
         }
      }
   ];

   return (
      <>
         <div className="grid-actions-wrapper">
            <button className="button button-primary" onClick={() => setShowModal(true)}>Add workshop</button>
         </div>
         <div className='grid-wrapper'>
            <div id='workshop-grid' ref={workShopGridRef}></div>
         </div>
         {
            spinnerService.spinner
         }
         {
            showModal && <AddWorkshopModal
               closeModal={() => setShowModal(false)}
               addWorkShop={data => addWorkShop(data, authStore.userData.accessToken)}
               dataLoading={dataLoading}
            />
         }
      </>
   );

   function parseWorkShops(workShopList: WorkshopCodeDto[]) {
      return workShopList.map(w => ({...w, dateAdded: getFormattedTime(w.dateAdded.toString())}));
   }

   function initializeComponent(accessToken: string) {
      const workshopListURL = process.env.REACT_APP_PUBLIC_URL + "/augmentation/workshop-list";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .get(workshopListURL, {headers})
         .then((response$: AxiosResponse<WorkshopCodeDto[]>) => {
            setWorkShopList(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     initializeComponent(response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function addWorkShop(data: WorkShopAddDto, accessToken: string) {
      const addWorkShopURL = process.env.REACT_APP_PUBLIC_URL + "/augmentation/save-workshop";

      setDataLoading(true);

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(addWorkShopURL, data,{headers})
         .then((response$: AxiosResponse<WorkshopCodeDto[]>) => {
            setWorkShopList(response$.data);
            setDataLoading(false);
            setShowModal(false);
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     addWorkShop(data, response$.data.accessToken);
                  })
            } else {
               setDataLoading(false);
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function updateWorkShop(data: WorkShopUpdateDto, accessToken: string) {
      const updateWorkShopURL = process.env.REACT_APP_PUBLIC_URL + "/augmentation/update-workshop";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      axios
         .post(updateWorkShopURL, data,{headers})
         .then((response$: AxiosResponse<WorkshopCodeDto[]>) => {
            setWorkShopList(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     updateWorkShop(data, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

   function deleteWorkShop(code: string, accessToken: string) {
      const deleteWorkShopURL = process.env.REACT_APP_PUBLIC_URL + "/augmentation/delete-workshop";

      spinnerService.createSpinner();

      const headers: AxiosRequestConfig['headers'] = {
         'Authorization': `Bearer ${accessToken}`
      }

      const data = {
         code
      }

      axios
         .post(deleteWorkShopURL, data,{headers})
         .then((response$: AxiosResponse<WorkshopCodeDto[]>) => {
            setWorkShopList(response$.data);
            spinnerService.removeSpinner();
         })
         .catch((error$: ErrorResponseDto) => {
            if (error$.response.data.message === 'Unauthorized') {
               // Get new Access Token
               refreshToken(authStore.userData.refreshToken)
                  .then((response$: any) => {
                     authStore.storeTokens(response$.data.accessToken, response$.data.refreshToken, response$.data.sessionId);
                     deleteWorkShop(code, response$.data.accessToken);
                  })
            } else {
               spinnerService.removeSpinner();
               showNotification('warning', error$.response.data.message);
            }
         })
   }

}

export default WorkshopComponent;