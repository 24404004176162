import styles from './LoginFormComponent.module.scss';

import SpinnerButton from '../../../ui/SpinnerButton/SpinnerButton';
import {NavLink} from 'react-router-dom';
import React, {FormEvent, useState} from "react";
import {LoginData, UserLoginFormParams} from "../../../types/LoginRegisterData";


const LoginFormComponent: React.FC<UserLoginFormParams> = (props) => {

   const [formData, setFormData] = useState<LoginData>({
      username: '',
      password: ''
   });
   const [formErrorData, setFormErrorData] = useState({
      username: false,
      password: false
   });
   const [typePassword, setTypePassword] = useState(true);

   return (
      <div className={styles['login-form']}>
         <form onSubmit={checkForm}>
            <label htmlFor="username" className="form-label">Email</label>
            <div className={`mb-4 input-wrapper ${formErrorData.username ? "error" : ""}`}>
            <span className='icon-container'>
              <i className="fa-solid fa-envelope"></i>
            </span>
               <input value={formData.username} onChange={handleChange} type="text" className={`form-control`}
                      id="username" name={"username"} autoComplete='off' placeholder='Enter your email address'
                      disabled={props.isDataLoading} autoFocus/>
            </div>
            <label htmlFor="password" className="form-label">Password</label>
            <div className={`mb-4 input-wrapper ${formErrorData.password ? "error" : ""}`}>
            <span className='icon-container'>
              <i className="fa-solid fa-lock"></i>
            </span>
               <input value={formData.password} onChange={handleChange} type={typePassword ? 'password' : 'text'}
                      className={`form-control`} id="password" name={"password"} autoComplete='off'
                      placeholder='Enter your password'/>
               <span className={'eye'} onClick={toggleShowPassword}><i
                  className={typePassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}></i></span>
            </div>
            {
               props.error !== "" && <div className="alert alert-danger alerting mt-3" role="alert">{props.error}</div>
            }
            <div>
               {
                  <SpinnerButton title='Login' buttonType='login' cssClasses='button button-primary u-margin-top-m'
                                 dataLoading={props.isDataLoading}/>
               }
            </div>
            <div className="forgot-password mt-2">
               <NavLink to={'/reset-password'} className='button button-tertiary forgot-password-link'>Forgot
                  password?</NavLink>
            </div>
            <div className="forgot-password">
               <NavLink to={'/register-email'} className='button button-tertiary forgot-password-link'>Go to register</NavLink>
            </div>
         </form>
      </div>
   )

   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const {name, value} = e.target;
      setFormData({
         ...formData,
         [name]: value
      })
   }

   function checkForm(event: FormEvent<HTMLFormElement>) {
      event.preventDefault();
      let username = formData.username.trim().length === 0;
      let password = formData.password.trim().length === 0;
      setFormErrorData({username, password});
      if (username || password) {
         return;
      }
      props.submitFormFunction(formData);
   }

   function toggleShowPassword() {
      setTypePassword((prevState) => {
         return !prevState;
      })
   }
}

export default LoginFormComponent;