import React, {useContext, useEffect, useRef, useState} from 'react';
import {NavLink, useLocation, useNavigate} from 'react-router-dom';

import AuthContext from '../../../store/auth-context';

import styles from './MenuComponent.module.scss';
import {MenusEndpointsDto} from "../../../types/UserMenu";
import useResponsiveComponent from "../../../ui/ResponsiveComponent/ResponsiveComponent";

const MenuComponent: React.FC<{smallMenu: boolean, changeMenu: Function}> = props => {
	const authStore = useContext(AuthContext);
	const location = useLocation();
	const navigate = useNavigate();
	const width = useResponsiveComponent();

	const userMenuData = authStore.userData.menu;
	const menuToggleButton = useRef<HTMLSpanElement>(null);

	// Finding upper and lower menu data
	const upperMenuData = userMenuData.find(item => item.title === 'Upper Left');
	const lowerMenuData = userMenuData.find(item => item.title === 'Lower Left');

	const [openedSubMenu, setOpenedSubMenu] = useState(false);

	// Determine if there is a multi-menu
	const isMultiMenu = !!(upperMenuData && upperMenuData.menus && upperMenuData.menus.length > 1);

	// Find the URLs for the home pages
	const { urlDefaultHomePage, urlManagerHomePage, urlEmployeeHomePage } = findHomePage(userMenuData);

	// Effect to handle active section if multi-menu
	useEffect(() => {
		if (isMultiMenu) {
			findActiveSection();
		}
	}, [isMultiMenu]);

	useEffect(() => {
		let menu = document.getElementById('main-menu') as HTMLDivElement;
		if (props.smallMenu) {
			toggleMainMenu(undefined);
		}
		else if (menu.classList.contains("opened")) {
			toggleMainMenu(undefined);
		}
	}, [props.smallMenu]);
	
	return (
		<>
			{ (upperMenuData || lowerMenuData) &&
				<div className={`${styles['main-menu']} ${width.windowWidth <= 768 ? styles.small : ""} ${width.windowWidth <= 768 && props.smallMenu ? styles.openedMenu : ""}`}>
					{
						width.windowWidth > 768 &&
						<div className='sidebar-collapse'>
							<i ref={menuToggleButton} className="fa-solid fa-circle-chevron-right" onClick={(e) => toggleMainMenu(e)}></i>
						</div>
					}

					{/* Logo section */}
					<div className='logo-container'>
						<span id='logo-standard' className=''>
							<img src='/assets/images/global-icons/teamlift-logo-white.svg' alt='TeamLift logo' />
						</span>
							<span id='logo-short' className='visible'>
								<img src='/assets/images/global-icons/teamlift-icon-white.svg' alt='TeamLift logo' />
							</span>
						{
							width.windowWidth <= 768 && <i className="fa-solid fa-xmark" onClick={() => props.changeMenu()}></i>
						}
					</div>

					<div id='main-menu' className="navigation">
						{
							isMultiMenu && upperMenuData && <div className='menu-section-switch-buttons-wrapper'>
								<>
									<div id='menu-section-switch-buttons-wrapper-long' className="">
										{
											upperMenuData?.menus!.map(item => {
												return <div key={item.id} className='menu-switch-button' id={item.title.replace(" ", "_") + '_menu-switch-button'}
													onClick={() => showMenuOptionSection(false, item.title.replace(" ", "_"))}><span>{item.title}</span></div>
											})
										}
									</div>
									<div id='menu-section-switch-buttons-wrapper-short' className="opened">
										<div className='menu-switch-button selected-section-button' id='menu-switch-button-short'
										onClick={() => showMenuOptionSection(true)}></div>
									</div>
								</>
							</div>
						}
						{
							upperMenuData && upperMenuData.menus!.map(menu => {
								return <div key={menu.id} id={menu.title.replace(" ", "_") + '_section'} className={!isMultiMenu ? 'active-section': ''}>
									{menu.menus!.map((menuItem) => {
										return getMenuItem(menuItem).map((item, index: number) => {
											return <React.Fragment key={index}>{renderMenuItem(item)}</React.Fragment>
										})
									})}
								</div>
							})
						}

						<div id='lowerMenuSection' className={upperMenuData ? 'has-top-border' : ''}>
							{ lowerMenuData &&
								lowerMenuData.menus!.map((menu: any) => {
									let linkProps: {iconName: string, iconDescription: string};
									let imageUrl: string;
									let imageDescription: string;
									linkProps = getMenuOption(menu.url);

									imageUrl = linkProps.iconName;
									imageDescription = linkProps.iconDescription;

									return (
										<NavLink key={menu.id} to={menu.url} className={({isActive}) => isActive ? "nav-item activeMenuItem" : "nav-item"}>
											{
												({isActive}) => (
													<>
														<img
															src={`/assets/images/menu-icons/new-icons${isActive ? '-active' : ''}/` + imageUrl} alt={imageDescription}/>
														<span className='menu-title'>{menu.description}</span>
													</>
												)
											}
										</NavLink>
									)
								})
							}
						</div>
					</div>
				</div>
			}
		</>
	)

	function renderMenuItem(menuItem: { element: any, parent: any, children: any}) {
		if (!menuItem.children) {
			return menuItem.element;
		}
		else {
			const activeChildren = menuItem.children.filter((child: any) => child.element.ref.current && child.element.ref.current.classList.contains("activeMenuItem")).length > 0;
			const imageUrl = getMenuOption(menuItem.children[0].element.props.to).iconName;
			return (
				<div className={`menu-item-container ${activeChildren ? "activeContainerItem" : ""}`}>
					<div className={`parent-element nav-item ${activeChildren ? "activeMenuItem" : ""}`} onClick={() => {

						let menu = document.getElementById('main-menu') as HTMLDivElement;

						if (!activeChildren) {
							menuItem.children[0].element.ref.current.click();
							if (!openedSubMenu && menu.classList.contains('opened')) {
								setOpenedSubMenu(true);
							}
						}
						else if (menu.classList.contains('opened')) {
							setOpenedSubMenu(prevState => !prevState);
						}
						else if (!menu.classList.contains('opened')) {
							if (!activeChildren) {
								menuItem.children[0].element.ref.current.click();
							}
							else {
								const index = menuItem.children.findIndex((child: any) => child.element.ref.current.classList.contains("activeMenuItem"));
								if (index > -1 && index < menuItem.children.length -1) {
									for (const child of menuItem.children.slice(index + 1)) {
										if (child.element.ref.current) {
											child.element.ref.current.click();
											break;
										}
									}
								} else {
									menuItem.children[0].element.ref.current.click();
								}
							}
						}}}>
						<img src={`/assets/images/menu-icons/new-icons${activeChildren ? "-active" : ""}/${imageUrl}`}
							  alt="Projects icon"/>
						<span className="menu-title">{menuItem.element.title}</span>
						<i className={`fa-solid fa-chevron-down u-margin-left-xs ${openedSubMenu ? "rotate" : ""}`}></i>
					</div>
					<div className={`child-elements ${openedSubMenu ? "" : "closed"}`}>
						{menuItem.children.map((child: { element: any; parent: any; children: any; }, index: number) => (
							<div key={index}>
								{renderMenuItem(child)}
							</div>
						))}
					</div>
				</div>
			);
		}
	}

	function getMenuItem(menuItem: any, parent: any = null) {
		let results: any[] = [];

		if (menuItem.menus && menuItem.menus.length > 0) {
			if (!results.find(e => e.element === menuItem)) {
				results.push({element: menuItem, parent: parent, children: []});
			}
			menuItem.menus.forEach((m: any) => {
				const subMenu = getMenuItem(m, menuItem);
				results.find(e => e.element === menuItem).children.push(subMenu[0]);
			});
		} else {
			let linkProps: { iconName: string, iconDescription: string };
			let imageUrl: string;
			let imageDescription: string;
			const menuRef = useRef<any>();
			linkProps = getMenuOption(menuItem.url);
			imageUrl = linkProps.iconName;
			imageDescription = linkProps.iconDescription;

			const result = !menuItem.hidden && (
				<NavLink
					key={menuItem.id}
					to={menuItem.url}
					className={({ isActive }) => isActive ? "nav-item activeMenuItem" : "nav-item"}
					ref={menuRef}
				>
					{({ isActive }) => (
						<>
							<img
								src={`/assets/images/menu-icons/new-icons${isActive ? '-active' : ''}/${imageUrl}`}
								alt={imageDescription}
							/>
							<span className='menu-title'>{menuItem.description}</span>
						</>
					)}
				</NavLink>
			);

			if (result) {
				results.push({element: result, parent: parent});
			}
		}
		return results;
	}

	function toggleMainMenu(_event: React.MouseEvent | undefined, toggleButtonClick: boolean = true) {
		let menu = document.getElementById('main-menu') as HTMLDivElement;
		setOpenedSubMenu(false);

		if (toggleButtonClick) {
			menu.classList.toggle('opened');		
			menuToggleButton.current?.classList.toggle('rotate');

			if (isMultiMenu) {
				let buttonsSectionLong: HTMLDivElement = document.getElementById('menu-section-switch-buttons-wrapper-long') as HTMLDivElement;
				let buttonsSectionShort: HTMLDivElement = document.getElementById('menu-section-switch-buttons-wrapper-short') as HTMLDivElement;

				buttonsSectionLong.classList.toggle('opened');
				buttonsSectionShort.classList.toggle('opened');
			}

			let logoContainerStandard = document.getElementById('logo-standard') as HTMLSpanElement;
			let logoContainerShort = document.getElementById('logo-short') as HTMLSpanElement;

			logoContainerStandard.classList.toggle('visible');
			logoContainerShort.classList.toggle('visible');
		}
		else if (!menu.classList.contains('opened')) {
			menu.classList.add('opened');		
			menuToggleButton.current?.classList.add('rotate');

			if (isMultiMenu) {
				let buttonsSectionLong: HTMLDivElement = document.getElementById('menu-section-switch-buttons-wrapper-long') as HTMLDivElement;
				let buttonsSectionShort: HTMLDivElement = document.getElementById('menu-section-switch-buttons-wrapper-short') as HTMLDivElement;
				buttonsSectionLong.classList.add('opened');
				buttonsSectionShort.classList.add('opened');
			}

			let logoContainerStandard = document.getElementById('logo-standard') as HTMLSpanElement;
			let logoContainerShort = document.getElementById('logo-short') as HTMLSpanElement;

			logoContainerStandard.classList.toggle('visible');
			logoContainerShort.classList.toggle('visible');
		}
	}

	function getMenuOption(menuUrl: string): {iconName: string, iconDescription: string} {
		let menuOption: {iconName: string, iconDescription: string} = {
			iconName: "",
			iconDescription: ""
		};

		switch (menuUrl) {
			case '/employee/skills-wallet':
				menuOption = {iconName: 'skills-wallet-icon.svg', iconDescription: 'Skills wallet Icon'};
				break;
			case '/answer/calculate-results':
				menuOption = {iconName: 'skills-wallet-icon.svg', iconDescription: 'Skills wallet Icon'};
				break;
			case '/file/personal-data-types':
				menuOption = {iconName: 'upload-data-icon.svg', iconDescription: 'File import Icon'};
				break;	
			case '/employee/employee-dashboard':
				menuOption = {iconName:'employee-dashboard-icon.svg', iconDescription: 'Employee dashboard Icon'}; 
				break;
			case '/employee/skill-growth':
				menuOption = {iconName:'employee-skill-growth-icon.svg', iconDescription: 'Employee Skill Growth Icon'}; 
				break;
			// case '/employee/tree-view':
			// 	menuOption = {iconName: 'menu-tree-icon.svg', iconDescription: 'Tree Chart Icon'};
			// 	break;			
			case '/manager/projects':
				menuOption = {iconName: 'projects-icon.svg', iconDescription: 'Dashboard Icon'};
				break;
			case '/manager/tasks':
				menuOption = {iconName: 'job-postings-icon.svg', iconDescription: 'Tasks Icon'};
				break;
			case '/manager/chat-bot':
				menuOption = {iconName: 'chat-bot-icon.svg', iconDescription: 'Dashboard Icon'};
				break;
			case '/manager/projects-skill-set':
				menuOption = {iconName: 'skill-inventory-icon.svg', iconDescription: 'Project Skills Inventory Icon'};
				break;
			case '/manager/company-skill-set':
				menuOption = {iconName: 'skill-inventory-icon.svg', iconDescription: 'Company Skills Inventory Icon'};
				break;
			case '/file/company-data-types':
				menuOption = {iconName: 'upload-data-icon.svg', iconDescription: 'File Import Icon'};
				break;
			// case '/manager/manager-dashboard':
			// 	menuOption = {iconName: 'menu-manager-dashboard-icon.svg', iconDescription: 'Manager Dashboard Icon'};
			// 	break;
			case '/auth/personal-info':
				menuOption = {iconName: 'settings-icon.svg', iconDescription: 'Settings Icon'};
				break;
			case '/admin-onboard/admin-panel':
				menuOption = {iconName: 'admin-panel-icon.svg', iconDescription: 'Admin Panel Icon'};
				break;
			case '/super-admin/super-admin-panel':
				menuOption = {iconName: 'admin-panel-icon.svg', iconDescription: 'Admin Panel Icon'};
				break;
			case '/augmentation/augmentation-report':
				menuOption = {iconName: 'augmentation-report-icon.svg', iconDescription: 'Augmentation Report Icon'};
				break;
			case '/annotator/annotator-panel':
				menuOption = {iconName: 'admin-panel-icon.svg', iconDescription: 'Annotator and Admin Panel Icon'};
				break;
		}
		return menuOption;
	}

	function showMenuOptionSection (toggle: boolean, section?: string) {
		let managerSection: HTMLDivElement = document.getElementById(`${userMenuData[0].menus![0].title.replace(" ", "_")}_section`) as HTMLDivElement;
		let employeeSection: HTMLDivElement = document.getElementById('Employee_section') as HTMLDivElement;
		let managerButton: HTMLDivElement = document.getElementById(`${userMenuData[0].menus![0].title.replace(" ", "_")}_menu-switch-button`) as HTMLDivElement;
		let employeeButton: HTMLDivElement = document.getElementById('Employee_menu-switch-button') as HTMLDivElement;
		let switchButtonShort: HTMLDivElement = document.getElementById('menu-switch-button-short') as HTMLDivElement;

		if (toggle) {
			if (switchButtonShort.innerText === 'M') {
				switchButtonShort.innerText = 'E';
				navigate(urlEmployeeHomePage);
			}
			else {
				switchButtonShort.innerText = 'M';
				navigate(urlManagerHomePage);
			}
			managerSection.classList.toggle('active-section');
			managerButton.classList.toggle('selected-section-button');
			employeeSection.classList.toggle('active-section');
			employeeButton.classList.toggle('selected-section-button');
		}
		else {
			if (section?.includes('Manager') || section?.includes('Admin')) {
				switchButtonShort.innerText = 'M';
				navigate(urlManagerHomePage);
				managerSection.classList.add('active-section');
				managerButton.classList.add('selected-section-button');
				employeeSection.classList.remove('active-section');
				employeeButton.classList.remove('selected-section-button');
			}
			else {
				switchButtonShort.innerText = 'E';
				navigate(urlEmployeeHomePage);
				managerSection.classList.remove('active-section');
				managerButton.classList.remove('selected-section-button');
				employeeSection.classList.add('active-section');
				employeeButton.classList.add('selected-section-button');
			}
		}
	}

	function findActiveSection(): void {
		let managerSection: HTMLDivElement = document.getElementById(`${userMenuData[0].menus![0].title.replace(" ", "_")}_section`) as HTMLDivElement;
		let employeeSection: HTMLDivElement = document.getElementById('Employee_section') as HTMLDivElement;
		let managerButton: HTMLDivElement = document.getElementById(`${userMenuData[0].menus![0].title.replace(" ", "_")}_menu-switch-button`) as HTMLDivElement;
		let employeeButton: HTMLDivElement = document.getElementById('Employee_menu-switch-button') as HTMLDivElement;
		let switchButtonShort: HTMLDivElement = document.getElementById('menu-switch-button-short') as HTMLDivElement;
		let homepageIndex: number = -1;
		let activeRoute = location.pathname;

		if (activeRoute === '/auth/personal-info' || activeRoute === '/admin-onboard/admin-panel') {
			if (upperMenuData!.menus!.length === 2) {
					managerSection.classList.add('active-section');
					managerButton.classList.add('selected-section-button');
					employeeSection.classList.remove('active-section');
					employeeButton.classList.remove('selected-section-button');
					switchButtonShort.innerText = 'M';
			}
			else {
				let title: string = upperMenuData!.menus![0].title;

				if (title.includes('Manager') || title.includes('Admin')) {
					managerSection.classList.add('active-section');
					managerButton.classList.add('selected-section-button');
					employeeSection.classList.remove('active-section');
					employeeButton.classList.remove('selected-section-button');
					switchButtonShort.innerText = 'M';
				}
				else if (title === 'Employee') {
					managerSection.classList.remove('active-section');
					managerButton.classList.remove('selected-section-button');
					employeeSection.classList.add('active-section');
					employeeButton.classList.add('selected-section-button');
					switchButtonShort.innerText = 'E';
				}
			}
		}
		else {
			setStyles();
			
			// If it's custom url that doesn't exist in manager or employee menu routes
			if (homepageIndex === -1) {
				activeRoute = urlDefaultHomePage;
				setStyles();
			}
		}

		function setStyles() {
			for (let menu of upperMenuData!.menus!) {
				homepageIndex = findActivePage(menu, activeRoute) ? 1 : -1;

				if (homepageIndex > -1) {
					if (menu.title.includes("Manager") || menu.title.includes("Admin")) {
						managerSection.classList.add('active-section');
						managerButton.classList.add('selected-section-button');
						employeeSection.classList.remove('active-section');
						employeeButton.classList.remove('selected-section-button');
						switchButtonShort.innerText = 'M';
					}
					else if (menu.title === 'Employee') {
						managerSection.classList.remove('active-section');
						managerButton.classList.remove('selected-section-button');
						employeeSection.classList.add('active-section');
						employeeButton.classList.add('selected-section-button');
						switchButtonShort.innerText = 'E';
					}
					break;
				}
			}
		}
	}

	function findHomePage(userMenu: MenusEndpointsDto[]) {
		let urlDefaultHomePage: any = '';
		let urlEmployeeHomePage: any = '';
		let urlManagerHomePage: any = '';

		let firstPageAsDefaultManager = false;
		
		let upperLeftMenu = userMenu.find(item => item.title === 'Upper Left')!;
		let homepage = null;

		if (upperLeftMenu) {
			// User has only one role
			if (upperLeftMenu.menus!.length === 1) {
				homepage = findHomePageItem(upperLeftMenu.menus![0]);

				if (upperLeftMenu.menus![0].title.includes('Manager') || upperLeftMenu.menus![0].title.includes('Admin')) {
					if (homepage && homepage.url) {
						urlManagerHomePage = homepage.url;
						urlDefaultHomePage = homepage.url;
					}	
					else {
						urlManagerHomePage = upperLeftMenu.menus![0].menus![0].url;
						urlDefaultHomePage = upperLeftMenu.menus![0].menus![0].url;
					}
				}
				else {
					if (homepage && homepage.url) {
						urlEmployeeHomePage = homepage.url;
						urlDefaultHomePage = homepage.url;
					}
					else {
						urlEmployeeHomePage = upperLeftMenu.menus![0].menus![0].url;
						urlDefaultHomePage = upperLeftMenu.menus![0].menus![0].url;
					}			
				}
			}
			else { // Both roles
				for (let menu of upperLeftMenu.menus!) {
					homepage = findHomePageItem(menu);
					
					if (menu.title.includes("Manager") || menu.title.includes("Admin")) {
						if (homepage && homepage.url) {
							urlManagerHomePage = homepage.url;
						}
						else {
							urlManagerHomePage = menu.menus![0].url;
							firstPageAsDefaultManager = true;
						}
					}
					else {
						if (homepage && homepage.url) {
							urlEmployeeHomePage = homepage.url;
						}
						else {
							urlEmployeeHomePage = menu.menus![0].url;
						}
					}	
				}
				urlDefaultHomePage = firstPageAsDefaultManager ? urlEmployeeHomePage : urlManagerHomePage;
			}
		}

		return {urlDefaultHomePage: urlDefaultHomePage, urlManagerHomePage, urlEmployeeHomePage }
	}

	function findActivePage(menuItem: MenusEndpointsDto, activePage: string): MenusEndpointsDto | null {
		if (menuItem.url && activePage.includes(menuItem.url)) {
			return menuItem;
		} else if (menuItem.menus) {
			for (const m of menuItem.menus) {
				const foundMenuItem = findActivePage(m, activePage);
				if (foundMenuItem) {
					return foundMenuItem;
				}
			}
		}
		return null;
	}

	function findHomePageItem(menuItem: MenusEndpointsDto): MenusEndpointsDto | null {
		if (menuItem.url && menuItem.homepage) {
			return menuItem;
		} else if (menuItem.menus) {
			for (const m of menuItem.menus) {
				const foundMenuItem = findHomePageItem(m);
				if (foundMenuItem) {
					return foundMenuItem;
				}
			}
		}
		return null;
	}

};

export default MenuComponent;