import React, {useState} from "react";
import style from './AddWorkshopModal.module.scss';
import {WorkShopAddDto} from "../../../../../types/SuperAdminData";
import SpinnerButton from "../../../../../ui/SpinnerButton/SpinnerButton";

const AddWorkshopModal: React.FC<{
   addWorkShop?: (workShop: WorkShopAddDto) => void;
   dataLoading: boolean;
   closeModal: () => void
}> = props => {

   const [formData, setFormData] = React.useState<WorkShopAddDto>({
      name: '',
      code: '',
      isVisible: false
   });

   const [formErrorData, setFormErrorData] = useState({
      name: false,
      code: false,
   });

   const [error, setError] = useState('');

   return (
      <>
         <div className={style["add-workshop-modal"]}>
            <div className="workshop-card">

               <h1 className="header-1">Add workshop</h1>

               <div className="form-wrapper">

                  <form className={'custom-form'}>

                     <div className="form-child">
                        <label htmlFor="name" className="custom-label">Workshop name</label>
                        <input value={formData.name} onChange={handleChange} type="text"
                               className={`custom-input ${formErrorData.name && "error"}`}
                               id="name" name="name" autoComplete='off'
                               placeholder='Enter workshop name'/>
                     </div>

                     <div className="form-child">
                        <label htmlFor="code" className="custom-label">Code</label>
                        <input value={formData.code} onChange={handleChange} type="text"
                               className={`custom-input ${formErrorData.code && "error"}`}
                               id="code" name="code" autoComplete='off'
                               placeholder='Enter workshop code'/>
                     </div>

                     <div className="form-child check">
                        <div className={`checkbox-dark ${formData.isVisible ? 'checked' : ''}`}
                             onClick={() => setFormData(prevState => ({...prevState, isVisible: !prevState.isVisible}))}><i className="fa-solid fa-check"></i>
                        </div>
                        <div className={'checkbox-label'}>This workshop will be visible.</div>
                     </div>

                     {
                        error !== "" &&
                        <div className="custom-error"><i className="fa-solid fa-triangle-exclamation"></i>{error}
                        </div>
                     }
                  </form>

                  <div className="buttons-wrapper">
                     <button className="button button-secondary" onClick={props.closeModal}>Close workshop</button>
                     <SpinnerButton cssClasses="button button-primary" title={"Add workshop"} onClickCallback={addWorkShop} dataLoading={props.dataLoading} />
                  </div>

               </div>
            </div>
         </div>
      </>
   );

   function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      const {name, value} = e.target;
      setFormData({
         ...formData,
         [name]: value
      })
   }

   function addWorkShop() {
      const nameError = formData.name.trim().length === 0;
      const codeError = formData.code.trim().length === 0 || formData.code.trim().length > 6;

      if (nameError || codeError) {
         setFormErrorData({name: nameError, code: codeError});
         if (formData.code.trim().length > 6) {
            setError("Code should be at maximum 6 length.")
         }
         else {
            setError('');
         }
         return;
      }

      props.addWorkShop && props.addWorkShop(formData);
   }
}

export default AddWorkshopModal;