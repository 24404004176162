import React, {useState} from "react";
import style from './RegisterEmailPage.module.scss';
import axios, {AxiosResponse} from "axios";
import {ErrorResponseDto} from "../../types/ErrorData";
import {adminRegexPatterns} from "../../types/AdminData";
import SpinnerButton from "../../ui/SpinnerButton/SpinnerButton";
import {useNavigate} from "react-router-dom";
import useResponsiveComponent from "../../ui/ResponsiveComponent/ResponsiveComponent";
import {animated} from "react-spring";
import ReactConfetti from "react-confetti";
import {createTransition} from "../../utils/springStyles";

const RegisterEmailPage: React.FC = () => {

   const [email, setEmail] = useState('');
   const [showError, setShowError] = useState<string>();
   const [showMessage, setShowMessage] = useState(false);
   const [dataLoading, setDataLoading] = useState(false);
   const [confetti, setConfetti] = useState(false);
   const navigate = useNavigate();
   const {windowWidth, windowHeight} = useResponsiveComponent();

   const transitions = createTransition(showMessage, 750, 100);

   return (
      <>
         <ReactConfetti recycle={false} numberOfPieces={confetti ? 1500 : 0} width={windowWidth} height={windowHeight} onConfettiComplete={
            confettiInstance => {
               setConfetti(false);
               confettiInstance?.reset();
            }} />
         <div className={style['email-register-page']}>
            <div className="page-wrapper">

               {
                  transitions((styles, item) => {
                     return item ?
                        <animated.section style={styles} className={'form-section'}>
                           <div className="header-wrapper">
                              <h1 className="header-1">Open your email!</h1>
                              <p>We've just sent you an email with a registration link. Don't worry, it's just a simple click to log in and keeps the spam away.</p>
                           </div>
                           <button onClick={() => setConfetti(true)} disabled={confetti} className="button button-secondary">Press for confetti</button>
                        </animated.section> :
                        <animated.section style={styles} className={'form-section'}>
                           <div className="header-wrapper">
                              <h1 className="header-1">Let’s take control!</h1>
                              <p>Let's take a quick look at your work. By the end, you'll have a clear idea of how AI tools can impact your role and which ones can help boost your skills!</p>
                           </div>
                           <div className="email-form-partial">
                              <div className="email-wrapper">
                                 <input type="text" value={email} placeholder={"your@email.com"}
                                        className={"custom-input"}
                                        onKeyDown={e => e.key === 'Enter' ? sendEmail() : () => {
                                        }}
                                        onChange={e => setEmail(e.target.value)}/>
                                 <div className="email-info">
                                    {
                                       showError ?
                                           <div className="custom-error-message">
                                              <div className="title">
                                                 <i className="fa-solid fa-circle-exclamation"></i>
                                                 <h4 className="header-4">Something went wrong</h4>
                                              </div>
                                              <p className={"error-body"}>{showError}</p>
                                           </div> :
                                           <>
                                              <i className="fa-solid fa-circle-info"></i>
                                              <span>Enter your email address</span>
                                           </>
                                    }
                                 </div>
                              </div>
                              <SpinnerButton title={showError ? 'Try again' : 'Take control'}
                                             cssClasses="button button-primary"
                                             onClickCallback={sendEmail} dataLoading={dataLoading}/>
                              <div className="already-account"><span>Already have an account?</span>
                                 <button className="button button-tertiary" onClick={() => navigate("/login")}>Log in
                                 </button>
                              </div>
                           </div>
                        </animated.section>
                  })
               }
               {
                  transitions((styles, item) => {
                     return item ?
                        <animated.section className="teamo-explain-section" style={styles}>
                           <img src="/assets/images/ai-impact-score-icons/teamo-stars-icon.svg"
                                alt="Teamo happy stars image" className={"teamo-image"}/>
                        </animated.section> :
                        <animated.section className={'teamo-explain-section'} style={styles}>
                           <img src="/assets/images/ai-impact-score-icons/teamo-hands-closed-eyes-icon.svg"
                                alt="Teamo hands and closed eyes" className={"teamo-image"}/>
                           {
                              windowWidth > 950 &&
                              <div className={'teamo-section'}>
                                 <div>
                                    <p>I’m Teamo! <br/> I’ll be your robot guide to the future!</p>
                                    <p>I will help you find the
                                       right tools for the job.</p>
                                 </div>
                                 <img src="/assets/images/ai-impact-score-icons/teamo-blue-cloud.svg"
                                      alt="Blue cloud"/>
                              </div>
                           }
                        </animated.section>

                  })
               }
            </div>
         </div>
      </>
   )

   function sendEmail() {
      const sendEmailURL = process.env.REACT_APP_PUBLIC_URL + '/auth/website/subscribe-user';

      if (!adminRegexPatterns.email.test(email)) {
         setShowError('Invalid email format');
         return;
      }

      const data = {
         email
      }

      setDataLoading(true);

      axios
         .post(sendEmailURL, data)
         .then((response$: AxiosResponse<boolean>) => {
            setDataLoading(false);
            setShowMessage(response$.data);
         })
         .catch((error$: ErrorResponseDto) => {
            setDataLoading(false);
            setShowError(error$.response.data.message);
         })

   }

}

export default RegisterEmailPage;