export type AdminPanelData = {
   isOnboarding: boolean;
   userData: UserDataDto[];
   existingUsers: UserDto[];
   chosenCategories: string[];
}

export type UserDataDto = {
   id: number;
   firstName: string;
   lastName: string;
   email: string;
   jobTitle: string;
   managerId: string;
   companyName: string;
   userType: string;
   availability: number;
   hireDate?: Date;
   yearsOfService?: Date;
   jobFamily?: string;
   processed?: boolean;
   color?: string;
   githubLink?: string;
   cvDriveLink?: string;
   linkedinLink?: string;
   isManager?: boolean;
   userRole?: string;
}

export type UserDto = {
   id: string;
   username: string;
   name: string;
   phone: string;
   displayName: string;
   joinedOn: Date;
   expirationDate: Date;
   autoGeneratedPassword: boolean;
   email: string;
   validity: number;
   level: Level;
   firstLogin: boolean;
   userRole?: Role;
   jobTitle?: string;
   availability?: number;
   githubLink?: string;
   isManager?: boolean;
}

export type Level = {
   id: number;
   name: string;
   dateCreated: Date;
   parent: Level;
   levelType: LevelType;
}

export enum Role {
   ADMIN = 'ADMIN',
   MANAGER = 'MANAGER',
   EMPLOYEE = 'EMPLOYEE',
   PERSONAL_USER = 'PERSONAL_USER',
   ADMIN_MANAGER = 'ADMIN_MANAGER',
   ADMIN_EMPLOYEE = 'ADMIN_EMPLOYEE',
}

export enum LevelType {
   OFFICE = 'OFFICE',
   COMPANY = 'COMPANY',
}

export type CompanyEmployeePayloadInformation = {
   id?: number,
   name: string,
   email: string,
   jobTitle: string,
   // availability: number,
   isManager: boolean
}

export type EmployeePayloadInformation = {
   id?: number,
   name: string,
   surname: string,
   email: string,
   jobTitle: string,
   // availability: number,
   githubLink?: string,
   cvDriveLink?: string,
   linkedinLink?: string,
   isManager: boolean
}

export enum EMPLOYEES_TYPE {
   employees = 'employees',
   onboard = 'onboard',
}

export const adminRegexPatterns = {
   email: /^[\w\.\-\+]+@\w+([\.-]?\w+)*(\.\w{1,})+$/,
   githubUrl: /^https:\/\/github\.com\/[A-Za-z0-9](?:[A-Za-z0-9]|-(?=[A-Za-z0-9])){0,38}$/,
   cvGoogleDriveLink: /https:\/\/drive\.google\.com\/file\/d\/[^/]+|https:\/\/docs\.google\.com\/document\/d\/([a-zA-Z0-9-_]+)(?:\/[\w\?\=\&\-]*)?/,
   linkedinLink: /https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+$/
}
